'use strict';

let addModulShowTipp = function(arg, playableNotes) {

  let privateVars = {
    showTippOnFingerBoard: false,
    showTippOnNoteBoard: false,
  };

  for (let key in privateVars) {
    playableNotes._vars[key] = privateVars[key];
    playableNotes["_" + key] = (arg != undefined && arg[key] != undefined) ? arg[key] : privateVars[key];
  }

  playableNotes.refreshShowTipp = function(note) {
    let realNotes = this._getNoteToXY(note);

    if (realNotes.length == 1) {
      note._showTipp = realNotes[0]._showTipp;
    }
  }

  playableNotes.showTipp = function(showB, note) {
    if (!this._showTippOnNoteBoard && !this._showTippOnFingerBoard)
      return false;

    let realNotes = this._getNoteToXY(note);
    console.log("MODULSHOWTIPP::showTipp ----------------------------------------", note, realNotes);

    // Tipp ist auf dem FingerBoard ...
    if (this._showTippOnFingerBoard) {
      //get playingLage
      let noteLage = note.lage
      let pLage = 100;
      let func = function(obj = pLage) {
        return function(p) {
          pLage = p;
        }
      }(pLage);
      this._fire(func, "GET_PLAYING_LAGE");

      console.log("MODULSHOWTIPP::showTipp", realNotes.length, pLage, note.softEqualVar(pLage, 'lage'));
      if (realNotes.length != 0 && note.softEqualVar(pLage, 'lage')) {
          console.log("MODULSHOWTIPP::showTipp mark Note");
        let isTippNote = false;

        for (let i = 0, max = realNotes.length; i < max; i++) {

          if (showB) {
             isTippNote = true;
            if (this._showB) {
              realNotes[i]._showTipp = showB;
              console.log("PRINT_GAMEBOARD_showTipp_note", note);
              this._fire(realNotes[i], "printNoteHighlightFingerBoard");
              this._fire(realNotes[i], "PRINT_GAMEBOARD_showTipp_note")
            }
          } else {
            realNotes[i]._showTipp = false;
            console.log("PRINT_GAMEBOARD_showTipp_remove", note, realNotes[i]);
            this._fire(realNotes[i], "printRemoveHighlight");
            this._fire(realNotes[i], "PRINT_GAMEBOARD_showTipp_remove");
            //return true;
          }
        }
        //the Tipp is set on a note
       if (isTippNote)
         return true;
      }

      // get Settings auto Lage
      let autoLage = {
        pLage: 100
      };
      let func2 = function(obj = autoLage) {
        return function(p) {
          obj = p;
          autoLage = p;

        }
      }(autoLage);
      this._fire(func2, "GET_PLAYING_LAGE_AUTO");

      console.log("MODULSHOWTIPP::showTipp mark Lage", !autoLage,  note._checkVarStatus(note.lage) != Note.STATUS.NOTSET,  this._showB);
      //wenn die Lage Manuell gesetzt wird & keine Noten Tipp gesetzt wurde
      if (!autoLage && note._checkVarStatus(note.lage) != Note.STATUS.NOTSET && this._showB && showB && !note.softEqualVar(pLage, 'lage')) {
         console.log("MODULSHOWTIPP::showTipp mark Lage");

        if (note._checkVarStatus(note.lage) == Note.STATUS.ARRAY) {
          note.lage = noteLage.filter(elem => elem.lage == pLage).length > 0 ? pLage : noteLage[0].lage;
        }
        //FIRE LAGE TIPP
        this._fire({
          down: noteLage < pLage,
          up: noteLage > pLage
        }, "printHighlightLage");
        this._fire({
          down: noteLage < pLage,
          up: noteLage > pLage
        }, "PRINT_GAMEBOARD_showTipp_lage");
      }
      return false;
    }




    if (this._showTippOnNoteBoard) {
      // set _showTipp on REAL NOTE .. .?
      for (let i = 0, max = realNotes.length; i < max; i++) {
        realNotes[i]._showTipp = showB;
      }
      if (showB) {
        console.log("MODULSHOWTIPP::showTippNoteBoard", note);
        this._fire(note, "showTippNoteBoard");
      } else
        console.log("MODULSHOWTIPP::showTippNoteBoard remove", note);
      this._fire(note, "printRemoveHighlight");
      this._fire(note, "PRINT_GAMEBOARD_showTipp_remove");
    }
  }
}
