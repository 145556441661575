'use strict';

class PlayableNotes extends Publisher {
  constructor(arg) {
    super();
    let privateVars = {
      notes: [],
      absoluteNoteNum: 0,
      showB: false   // playable Notes are visible (shown)
    };

    this.settings = null;

    this._vars = privateVars;

    for(let key in privateVars) {
      this["_"+key] = (arg != undefined && arg[key] != undefined) ? arg[key] : privateVars[key];
    }
  }

  isEqual(obj) {
    return (obj._notes == this._notes);
  }

  get showTipps() {
    return (this._showTippOnFingerBoard || this._showTippOnNoteBoard);
  }
  get length () {
    return this._notes.length;
  }
  get absLength() {
     return this._absoluteNoteNum;
 }

  _fire(arg, action) {
    super._fire(arg, action, "FORWARD_EVENT");
  }

  /*
  standard index returns real Note Object
  useAbsoluteIndex == true -> returns Clone
  */
  getNote(index, useAbsoluteIndex = false) {
    if(useAbsoluteIndex == false) {
      return this._notes[index];
    } else {
      let absIndex = 0;
      for(let i=0;i<this._notes.length;i++) {
        if(absIndex == index || this._notes[i].index == -1 && absIndex+1 == index) {
          let note = this._notes[i].cloneAll();
          if(note.index == -1)
            note.index = (absIndex == index) ? 0 : 1;
          return note;
        }

        absIndex += (this._notes[i].index == -1) ? 2 : 1;
      }
    }
  }

  getAllNotes() {
    return this._notes;
  }

  getRandomNoteClone(noteType) {
    let note = null;
    switch(noteType) {
      case NOTE_TYP.noteBoard:
        note = this.getNote(getRandomInt(this._absoluteNoteNum), true);
        note.setXY(this.getPosToName(note.name));
        return note;
        break;
      case NOTE_TYP.fingerBoard:
        note = this._notes[getRandomInt(this._notes.length)].cloneAll();
        return note;
        break;
    }
  }


  show() {
    if(this._showB == false) {
      this._showB = true;
      this._fire(this._notes, "printPlayableNotes");
    }
  }

  hide() {
    if(this._showB == true) {
      this._showB = false;
      this._fire({}, "printPlayableNotes");
    }
  }




// ----------------------------------- SET Functionen -----------------------------------

  set(arg) {
    keys = this._vars;
    let data = {};
    let newInit = false;

    for(let key in keys) {
      if(arg[key] != undefined && this["_"+key] != arg[key]) {
        data[key] = arg[key];
        newInit = true;
      }
      else {
        data[key] = this["_"+key];
      }
    }

    if(newInit) {
      new BuildPlayableNotes(data, this);
    }
  }

  observe(arg, action) {
    switch (action) {
    }
  }

/* ABSTRACT FUNCTIONS */
  showTipp(arg, note) {return false;}
  refreshShowTipp() {return true;}
  showName(arg, note) {return false;}
  refreshShowName() {return true;}
  resetTurnable() {}

  getPosToName() {return -1;}
  setLageIfPossible() {return false}
  getPossibleLageAndFinger() {return -1}
  setFingerRandom(note) {return note}
  getOtherPossibleName(note) {return false}


}
