// Javascript Document
'use strict';


class Publisher {
    constructor() {
        this._subscribers = {};
    }

    subscribe (fn, context=this, type = 'all') {
        //console.log("Publisher::subscribe", fn, type);

        if(typeof this._subscribers[type] === "undefined") {
            this._subscribers[type] = [];
        }

        let subs = this._subscribers[type];

        if(subs.includes(fn) == false); {
            subs.push({fn: fn, context: context});
        }
    }

    unsubscribe (fn, type='all') {
        //console.log("Publisher::unsubscribe");
        let subs = this._subscribers[type];
        for(let i=0;i<this._subscribers[type].length;i++) {
            if(this._subscribers[type][i].fn == fn) {
                this._subscribers[type].splice(i, 1);
                break;
            }
        }
    }

    _fire (arg, action, type='all', context = this) {
        var subs = null;

        if(type == 'all') {
            for(let t in context._subscribers) {
                subs = context._subscribers[t];
                for(let i=0, max = subs.length;i<max;i++) {
                        subs[i].fn.call(subs[i].context, arg, action, t);
                }
            }
        } else {
            var subs = context._subscribers[type];
            for(let i=0, max = (subs == undefined) ? 0 : subs.length;i<max;i++) {
                subs[i].fn.call(subs[i].context, arg, action, type);
            }
        }
    }
}

var publisher = {
    _subscribers: {},

    subscribe: function (fn, context=this, type = 'all') {
        console.log("Publisher::subscribe", fn, type);

        if(typeof this._subscribers[type] === "undefined") {
            this._subscribers[type] = [];
        }

        let subs = this._subscribers[type];

        if(subs.includes(fn) == false); {
            subs.push({fn: fn, context: context});
        }
    },

    unsubscribe: function (fn, type='all') {
        console.log("Publisher::unsubscribe");
        let subs = this._subscribers[type];
        for(let i=0, max = subs.length;i<max;i++) {
            if(subs[i].fn == fn) {
                this._subscribers[type].splice(index, 1);
            }
        }
    },

    _fire: function (arg, action, type='all') {
        var subs = this._subscribers[type];
        if(typeof subs == "undefined") {
            console.log("NO Subscribers");
            return ;
        }

        for(let i=0, max = subs.length;i<max;i++) {
            subs[i].fn.call(subs[i].context, arg, action);
        }

        if(type !== "all") {
            this._fire(arg, action);
        }

    }
}



var inherit = (function () {
    var F = function () {};
    return function (P, C) {
        console.log("inherit");
        F.prototype = P.prototype;
        C.prototype = new F();
        C.uber = P.prototype;
        C.prototype.constructor = C;
    }
})();

var makePublisherClass = function (obj) {
    obj.prototype = new Publisher();
}

var makePublisher = function (obj) {
    var log = "";
    for (let i in publisher) {
        log += i+", ";
        if(publisher.hasOwnProperty(i) /*&& typeof publisher[i] === 'function'*/) {
            obj[i] = publisher[i];
        }
    }
}
