'use strict';

class BuildPlayableNotes {
	constructor(arg, playableNotes) {

		if (playableNotes == undefined)
			playableNotes = new PlayableNotes(arg);
		else {
			playableNotes._vars = {
				notes: [],
				absoluteNoteNum: 0
			}
		}

		let privateVars = {
			basicTonesActive: true,
			lagen: [],
			group: GROUP_MODE.none,
			tonality: TONALITIES.whole,
		};

		for (let key in privateVars) {
			playableNotes._vars[key] = privateVars[key];
			playableNotes["_" + key] = (arg != undefined && arg[key] != undefined) ? arg[key] : privateVars[key];
		}



		if (arg != undefined && (arg.showTippOnFingerBoard || arg.showTippOnNoteBoard))
			addModulShowTipp(arg, playableNotes);
		if (arg != undefined && arg.isTurnable)
			addModulTurnable(arg, playableNotes);

		addModulIntelligentNotes(arg, playableNotes);


		let obj = this._getNotes(playableNotes);
		console.log("BUILDPLAYABLENOTES::_getNotes", obj);
		playableNotes._notes = obj.notes;
		playableNotes._absoluteNoteNum = obj.absoluteNoteNum;

		playableNotes.settings = {
			lagen: this._lagen,
			group: this._group,
			tonality: this._tonality
		};

		return playableNotes;
	}

	_getNotes(pNotes) {
		console.log("NOW");
		let basicTone = pNotes._basicTonesActive,
			lagen = pNotes._lagen,
			group = pNotes._group,
			tonality = pNotes._tonality;
		console.log("BUILDPLAYABLENOTES::_getNotes", basicTone, lagen, group, tonality);


		let newNotes = [];
		let matrix = VioLearn.matrix;

		let tonalityMatrix = VioLearn.tonalities[tonality];

		// wenn eine Gruppe verwendet wird kann nur eine Lage bespielt werden.
		if (group != -1 && lagen.length > 1) {
			lagen = [lagen[0]];
		}


		// Grundtöne
		if (basicTone) {
			for (let k = 0; k < tonalityMatrix[0].length; k++) {
				for (let l = 0; l < matrix[0][k].length; l++) {
					if (tonalityMatrix[0][k][l]) {
						newNotes.push(new PlayableNote({
							row: 0,
							column: k,
							name: matrix[0][k][l],
							index: l,
							playableNotes: pNotes,
							showName: (!pNotes._isTurnable) ? false : pNotes._defaultShowNameTurnable
						}));
					}
				}
			}
		}

		//for through lagen
		for (let i = 0; i < lagen.length; i++) {
			let lageSettings = VioLearn.lagen[lagen[i]];
			let startIndex = lageSettings.shift - 1;
			let endIndex = lageSettings.shift + VioLearn.settings.lageLength + 1;

			//for through Rows specific to the lage
			for (let j = startIndex; j < endIndex; j++) {
				//for through columns
				for (let k = 0; k < matrix[j].length; k++) {
					let arrIndex = [];

					//for through index
					for (let index = 0; index < matrix[j][k].length; index++) {
						//if group is Active and row is included in the group and it is nach der Fingereinstufung spielbar ODER Es ist keine group
						if (group != GROUP_MODE.none && VioLearn.groups[group].includes(j - (startIndex + 1)) && lageSettings.pos[j - startIndex][k][index] == VioLearn.groups[group].indexOf(j - (startIndex + 1)) || group == GROUP_MODE.none) {

							// check if the note is in the tonalityMatrix and check if the note is playable from a finger in the current Lage
							if (tonalityMatrix[j][k][index] == true && lageSettings.pos[j - startIndex][k][index] != VioLearn.FINGER_ENUM.notUsed) {
								arrIndex.push(index);
							}
						}
					}
					//console.log("Lage", lagen[i], "Row", j, "Column", k, "arrIndex", arrIndex.length, "matrixLength", matrix[j][k].length);
					if (arrIndex.length == matrix[j][k].length && matrix[j][k].length > 1) {
						newNotes.push(new PlayableNote({
							row: j,
							column: k,
							name: matrix[j][k],
							playableNotes: pNotes,
							showName: (!pNotes._isTurnable) ? false : pNotes._defaultShowNameTurnable
						}));
						//console.log(newNotes[newNotes.length-1]);
					} else {
						for (let m = 0; m < arrIndex.length; m++) {
							newNotes.push(new PlayableNote({
								row: j,
								column: k,
								name: matrix[j][k][arrIndex[m]],
								index: arrIndex[m],
								playableNotes: pNotes,
								showName: (!pNotes._isTurnable) ? false : pNotes._defaultShowNameTurnable
							}));
							//console.log(newNotes[newNotes.length-1]);
						}
					}

				}
			}
		}
		//console.log("newNotes " + newNotes.length, newNotes);

		let absoluteNum = 0;

		// remove double entries -> can be because of the multiple lagen iterations
		for (let i = 0; i < newNotes.length; i++) {
			for (let j = i + 1; j < newNotes.length; j++) {

				if (newNotes[i].isEqual(newNotes[j], true)) { // it's exactly the same note - hard Equal
					console.log("HARD EQUAL");
					newNotes.splice(j, 1);

				} else if (newNotes[i].row == newNotes[j].row && newNotes[i].column == newNotes[j].column) { // it's a different name with same position

					if (newNotes[i]._checkVarStatus(newNotes[i].name) == Note.STATUS.SET &&
						newNotes[i]._checkVarStatus(newNotes[j].name) == Note.STATUS.SET) { // beide Namen sind gesetzt
						if (newNotes[i].name == newNotes[j].name) { // Namen gleich - beim Hard Equal bei Ausnahmen nicht erfasst
							newNotes.splice(j, 1);
						} else {
							newNotes[i]._name = [newNotes[i].name, newNotes[j].name];
							newNotes[i]._index = -1;
							newNotes.splice(j, 1);

						}
					} else if (newNotes[i]._checkVarStatus(newNotes[i].name) == Note.STATUS.SET && newNotes[i]._checkVarStatus(newNotes[j].name) == Note.STATUS.ARRAY) {
						newNotes[i]._name = newNotes[j].name;
						newNotes[i]._index = -1;
						newNotes.splice(j, 1);

					} else if (newNotes[i]._checkVarStatus(newNotes[i].name) == Note.STATUS.ARRAY && newNotes[i]._checkVarStatus(newNotes[j].name) == Note.STATUS.SET && newNotes[i].name.includes(newNotes[j].name)) {
						newNotes.splice(j, 1);

					} else if (newNotes[i]._checkVarStatus(newNotes[i].name) == Note.STATUS.ARRAY && newNotes[i]._checkVarStatus(newNotes[j].name) == Note.STATUS.ARRAY) {
						newNotes.splice(j, 1);
					}
				}
			}
			if (newNotes[i].index == -1)
				absoluteNum += 2;
			else {
				absoluteNum++;
			}
		}

		console.log("NOTES", newNotes);
		console.log("NUM " + newNotes.length + " Absolute NUM " + absoluteNum);
		return {
			absoluteNoteNum: absoluteNum,
			notes: newNotes
		};

	}
}
