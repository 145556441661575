'use strict';

class PlayableNote extends Note {
  constructor(arg) {

     arg = (arg == undefined) ? {} : arg;

    super(arg)

    let privateVars = {
      showName: false,
      showTipp: false,
    };

    for(let key in privateVars) {
      this["_"+key] = (arg[key] != undefined) ? arg[key] : privateVars[key];
    }

  }

  set showName(arg) {
    if(arg != this._showName) {
      let ret = this._playableNotes.showName(arg, this);
      console.log("ret", ret);
      if(ret) {

         this._showName = arg;
      }
      return ret;
    }
    return false;
  }

  get showName() {
     this._playableNotes.refreshShowName(this);
    return this._showName;
  }

  set showTipp(arg) {
    if(arg != this._showTipp) {
      let ret = this._playableNotes.showTipp(arg, this);
      if(ret) {
        this._showTipp = arg;
      }
      return ret;
    }
    return false;
  }

  get showTipp() {
     this._playableNotes.refreshShowTipp(this);
    return this._showTipp;
  }

  cloneAll() {
      let obj = new PlayableNote();
      // clone Note
      obj._name = this._name;
      obj._index = this._index;
      obj._xy = this._xy;
      obj._lage = this._lage;
      obj._finger = this._finger;
      obj._playableNotes = this._playableNotes;
      // clone NoteFingerBoard
      obj._showTipp = this._showTipp;
      obj._showName = this._showName;
      return obj;
  }

}
