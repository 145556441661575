'use strict';

let addModulIntelligentNotes = function (arg, playableNotes) {

  playableNotes.getPosToName =  function (name) {
      //console.log("getPosToName", name);
      let pos = [];
      //mach es zu einem Array damit es in der Schleife übersichtlicher wird
      if (!Array.isArray(name))
          name = [name];

      for (let i = 0, max = this._notes.length; i < max; i++) {
          for (let j = 0; j < name.length; j++) {
              if (Array.isArray(this._notes[i].name) && this._notes[i].name.includes(name[j]) || this._notes[i].name == name[j]) {
                  pos.push({
                      row: this._notes[i].row,
                      column: this._notes[i].column
                  });
              }
          }
      }
      //console.log("pos", pos);
      if(pos.length < 1)
          pos = [{row: -1, column: -1}];
      return pos;
  }


  playableNotes.setLageIfPossible = function (note, lage) {
      let possibilities = this.getPossibleLageAndFinger(note);
      for (let i = 0; i < possibilities.length; i++) {
          if (possibilities[i].lage == lage) {
              note.lage = possibilities[i].lage;
          }
      }

      if (note.lage == lage) {
          return true;
      } else
          return false;
  }

  playableNotes.getPossibleLageAndFinger = function (note) {
      //console.log("getPossibleLageAndFinger", note);
      //console.log("getPossibleLageAndFinger");
      let lagenSettings = VioLearn.lagen;
      let possibilities = [];

      if (Array.isArray(note.row)) {
          for (let i = 0; i < note.row.length; i++) {
              let arr = this.getPossibleLageAndFinger({
                  row: note.row[i].row,
                  column: note.row[i].column,
                  index: note.index,
                  playableNotes: note._playableNotes
              });
              if (arr != -1)
                  possibilities = possibilities.concat(arr);
              else
                  return -1;
          }
          return possibilities;
      } else if (note.index == -1) {
          for (let i = 0; i < VioLearn.settings.maxNameNumForPos; i++) {
              let arr = this.getPossibleLageAndFinger({
                  row: note.row,
                  column: note.column,
                  index: i,
                  playableNotes: note._playableNotes
              });
              if (arr != -1)
                  possibilities = possibilities.concat(arr);
              else
                  return -1;
          }
          // reomove possible Double entries
          for (let i = 0; i < possibilities.length; i++) {
              for (let j = i + 1; j < possibilities.length; j++) {

                  if (possibilities[i].lage == possibilities[j].lage && possibilities[i].finger == possibilities[j].finger) {
                      possibilities.splice(j, 1);
                  }
              }
          }
          //console.log("----", possibilities);
          return possibilities;
      } else if (note.row == -1) {
          return -1;
      }

      // Grundtöne
      if (note.row == 0) {
          return [{
              lage: -1,
              finger: -1
          }];
        }
      // gehe durch die verfügbaren Lagen
      for (let i = 0; i < this._lagen.length; i++) {
          //console.log("check posibilities Lage"+this._lagen[i]);
          let startIndex = lagenSettings[this._lagen[i]].shift - 1;
          let endIndex = lagenSettings[this._lagen[i]].shift + VioLearn.settings.lageLength + 1;
          //console.log("Note.row = "+note.row, "startIndex = "+startIndex, "endIndex = "+endIndex, "tonality check "+VioLearn.tonalities[this._tonality][note.row][note.column][note.index])
          if (note.row < endIndex && note.row >= startIndex && VioLearn.tonalities[this._tonality][note.row][note.column][note.index]) {
              //console.log("push possibility");
              let index = note.row - startIndex;

              //console.log(lagenSettings[this._lagen[i]].pos[index][note.column][note.index], this._lagen[i], index, note.column, note)
              let finger = lagenSettings[this._lagen[i]].pos[index][note.column][note.index];

              if (finger != VioLearn.FINGER_ENUM.notUsed)
                  possibilities.push({
                      lage: this._lagen[i],
                      finger: finger
                  })
          }
      }
      //console.log("getPossibleLageAndFinger", possibilities);
      return possibilities;
  }

  playableNotes.setFingerRandom = function (note) {
      let possibilities = this.getPossibleLageAndFinger(note);
      let index = getRandomInt(possibilities.length);

      //bei den Grundtönen wird kein Finger und keine Lage gesetzt.
      if (possibilities[index].lage == -1 && possibilities.length == 1) {
          return note;
      } else if(possibilities[index].lage == -1){
        if(index>0)
          index--;
        else
          index++;
      }
      note._lage = possibilities[index].lage;
      note._finger = possibilities[index].finger;
      note._refresh();
      return note;
  }

  // is used from Turnable and ShowTipp Modul
  //returns the real Note(s) from playableNotes.notes in the current Lage that are at that XY Pos
  playableNotes._getNoteToXY = function (note, pLage = undefined) {
    if(pLage == undefined) {
      pLage = {pLage: 100};
      //Return function to get the current Lage
      let func = function (obj = pLage) { return function (p) {
        pLage = p;
      } }(pLage);
      this._fire(func, "GET_PLAYING_LAGE");
    }

    let copyNote = note.clone();
    copyNote._finger = -1;
    copyNote.lage = pLage;
   // console.log("MODULINTELLIGENTNOTES::_getNoteToXY", note, copyNote);

//console.log("MODULINTELLIGENTNOTES::_getNoteToXY", note._checkVarStatus(note._xy, "xy") == Note.STATUS.SET, copyNote.integrity);
    if(note._checkVarStatus(note._xy, "xy") == Note.STATUS.SET && copyNote.integrity) {
      for(let i=0;i<this._notes.length;i++) {
        if(this._notes[i].isEqual(note)) {
          return [this._notes[i]];
        }
      }
      return [];
    } else if(note._checkVarStatus(note._xy, "xy") == Note.STATUS.ARRAY){
      let retArr = [];
      for(let i=0, max = note._xy.length; i<max;i++) {
        copyNote._lage = -1;
        copyNote._finger = -1;
        copyNote.setXY([{...note._xy[i]}]);
        copyNote.lage = pLage;
        if(copyNote.integrity == true) {
          let tmp = this._getNoteToXY(copyNote);
          if(tmp.length > 0)
            retArr.push(tmp[0]);
        }
      }
      return retArr;
    } else {
      return [];
    }
  }

  playableNotes.getOtherPossibleName = function (note) {
    for(let i=0;i<this._notes.length;i++) {
      if(this._notes[i].softEqualVar(note.name, "name") && Array.isArray(this._notes[i].name)) {
          if(this._notes[i].name[0] == note.name) {
            return this._notes[i].name[1];
          } else {
            return this._notes[i].name[0];
          }
      }
    }
    return false;
  }

}
