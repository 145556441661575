// Main JS Code
'use strict';

DEBUG_STATUS = {
   NONE: 0,
   SELECTED: 1,
   ALL: 2
}
const debug = DEBUG_STATUS.NONE;

if(debug == DEBUG_STATUS.NONE) {
   console.log = function () {};
} else if(debug == DEBUG_STATUS.SELECTED) {

   const DEBUG_CLASSES = {
      ALL: false,
      GAMELOGIK: false,
      GAME: false,
      MODULTURNABLE: false,
      "finger-board": false,
      SETTINGS: true,
      "start-screen": false,
      "game.service": false,
      "level-progression" : true,
      screen: false
   }
   var oldConsoleLog = console.log;
   window['console']['log'] = function(...args) {
      let className = args[0];
      if(typeof className === "string") {
         className = className.split("_")[0].split("::")[0];
      }
      if(DEBUG_CLASSES[className] == true || DEBUG_CLASSES.ALL == true)
         oldConsoleLog(...args);
   };
}

/*
var logger = function()
{
    var oldConsoleLog = null;
    var pub = {};

    pub.enableLogger =  function enableLogger()
                        {
                            if(oldConsoleLog == null)
                                return;

                            window['console']['log'] = oldConsoleLog;
                        };

    pub.disableLogger = function disableLogger()
                        {
                            oldConsoleLog = console.log;
                            window['console']['log'] = function() {};
                        };

    return pub;
}();*/



/*
Matrix OLD
matrix: [
        [["g"],             ["d´"],             ["a´"],                 ["e´´"]],               //A
        [["gis", "as"],     ["dis´", "es´"],    ["ais´", "b´"],         ["f´´", "eis´´"]],      //B
        [["a"],             ["e´", "fes´"],     ["h´", "ces´´"],        ["fis´´", "ges´´"]],    //C
        [["ais", "b"],      ["f´", "eis´"],     ["c´´", "his´"],        ["g´´"]],               //D
        [["h", "ces´"],     ["fis´", "ges´"],   ["cis´´", "des´´"],     ["gis´´", "as´´"]],     //E
        [["c´", "his"],     ["g´"],             ["d´´"],                ["a´´"]],               //F
        [["cis´", "des´"],  ["gis´", "as´"],    ["dis´´", "es´´"],      ["ais´´", "b´´"]],      //G
        [["d´"],            ["a´"],             ["e´´", "fes´´"],       ["h´´", "ces´´´"]],     //H
        [["dis´", "es´"],   ["ais´", "b´"],     ["f´´", "eis´´"],       ["c´´´", "his´´"]],     //I
        [["e´", "fes´"],    ["h´", "ces´´"],    ["fis´´", "ges´´"],     ["cis´´´", "des´´´"]],  //J
        [["f´", "eis´"],    ["c´´", "his´"],    ["g´´"],                ["d´´´"]],              //K
        [["fis´", "ges´"],  ["cis´´", "des´´"], ["gis´´", "as´´"],      ["dis´´´", "es´´´"]],   //L
        [["g´"],            ["d´´"],            ["a´´"],                ["e´´´"]],              //M
        [["gis´", "as´"],   ["dis´´", "es´´"],  ["b´´", "ais´´"],       ["f´´´", "eis´´´"]],    //N
        [["a´"],            ["e´´", "fes´´"],   ["h´´", "ces´´´"],      ["fis´´´", "ges´´´"]],  //O
        [["ais´", "b´"],    ["f´´", "eis´´"],   ["c´´´", "his´´"],      ["g´´´"]],              //P
        [["h", "ces´´"],    ["fis´´", "ges´´"], ["cis´´´", "des´´´"],   ["gis´´´", "as´´´"]]    //Q
    ],

*/

const VioLearn = {
    matrix: [
        [["g"],             ["d´"],             ["a´"],                 ["e´´"]], //A
        [["gis", "as"],     ["dis´", "es´"],    ["ais´", "b´"],         ["f´´"]], //B
        [["a"],             ["e´"],             ["h´"],                 ["fis´´", "ges´´"]], //C
        [["ais", "b"],      ["f´"],             ["c´´"],                ["g´´"]], //D
        [["h"],             ["fis´", "ges´"],   ["cis´´", "des´´"],     ["gis´´", "as´´"]], //E
        [["c´"],            ["g´"],             ["d´´"],                ["a´´"]], //F
        [["cis´", "des´"],  ["gis´", "as´"],    ["dis´´", "es´´"],      ["ais´´", "b´´"]], //G
        [["d´"],            ["a´"],             ["e´´"],                ["h´´"]], //H
        [["dis´", "es´"],   ["ais´", "b´"],     ["f´´"],                ["c´´´"]], //I
        [["e´"],            ["h´"],             ["fis´´", "ges´´"],     ["cis´´´", "des´´´"]], //J
        [["f´"],            ["c´´",],           ["g´´"],                ["d´´´"]], //K
        [["fis´", "ges´"],  ["cis´´", "des´´"], ["gis´´", "as´´"],      ["dis´´´", "es´´´"]], //L
        [["g´"],            ["d´´"],            ["a´´"],                ["e´´´"]], //M
        [["gis´", "as´"],   ["dis´´", "es´´"],  ["ais´´", "b´´"],       ["f´´´"]], //N
        [["a´"],            ["e´´"],            ["h´´"],                ["fis´´´", "ges´´´"]], //O
        [["ais´", "b´"],    ["f´´"],            ["c´´´"],               ["g´´´"]], //P
        [["h"],             ["fis´´", "ges´´"], ["cis´´´", "des´´´"],   ["gis´´´", "as´´´"]] //Q
    ],
    //wholeNotes (Cdur)
    tonalities: {
        whole: [
        [[true, true], [true, true], [true, true], [true, true]], //A
        [[true, true], [true, true], [true, true], [true, true]], //B
        [[true, true], [true, true], [true, true], [true, true]], //C
        [[true, true], [true, true], [true, true], [true, true]], //D
        [[true, true], [true, true], [true, true], [true, true]], //E
        [[true, true], [true, true], [true, true], [true, true]], //F
        [[true, true], [true, true], [true, true], [true, true]], //G
        [[true, true], [true, true], [true, true], [true, true]], //H
        [[true, true], [true, true], [true, true], [true, true]], //I
        [[true, true], [true, true], [true, true], [true, true]], //J
        [[true, true], [true, true], [true, true], [true, true]], //K
        [[true, true], [true, true], [true, true], [true, true]], //L
        [[true, true], [true, true], [true, true], [true, true]], //M
        [[true, true], [true, true], [true, true], [true, true]], //N
        [[true, true], [true, true], [true, true], [true, true]], //O
        [[true, true], [true, true], [true, true], [true, true]], //P
        [[true, true], [true, true], [true, true], [true, true]] //Q
    ],
        cDur: [
        [[true, false], [true, false], [true, false], [true, false]], //A
        [[false, false], [false, false], [false, false], [true, false]], //B
        [[true, false], [true, false], [true, false], [false, false]], //C
        [[false, false], [true, false], [true, false], [true, false]], //D
        [[true, false], [false, false], [false, false], [false, false]], //E
        [[true, false], [true, false], [true, false], [true, false]], //F
        [[false, false], [false, false], [false, false], [false, false]], //G
        [[true, false], [true, false], [true, false], [true, false]], //H
        [[false, false], [false, false], [true, false], [true, false]], //I
        [[true, false], [true, false], [false, false], [false, false]], //J
        [[true, false], [true, false], [true, false], [true, false]], //K
        [[false, false], [false, false], [false, false], [false, false]], //L
        [[true, false], [true, false], [true, false], [true, false]], //M
        [[false, false], [false, false], [false, false], [true, false]], //N
        [[true, false], [true, false], [true, false], [false, false]], //O
        [[false, false], [true, false], [true, false], [true, false]], //P
        [[true, false], [false, false], [false, false], [false, false]] //Q
    ],
        bDur: [
        [[true, false], [true, false], [true, false], [false, false]], //A
        [[false, false], [false, true], [false, true], [true, false]], //B
        [[true, false], [false, false], [false, false], [false, false]], //C
        [[false, true], [true, false], [true, false], [true, false]], //D
        [[false, false], [false, false], [false, false], [false, false]], //E
        [[true, false], [true, false], [true, false], [true, false]], //F
        [[false, false], [false, false], [false, true], [false, true]], //G
        [[true, false], [true, false], [false, false], [false, false]], //H
        [[false, true], [false, true], [true, false], [true, false]], //I
        [[false, false], [false, false], [false, false], [false, false]], //J
        [[true, false], [true, false], [true, false], [true, false]], //K
        [[false, false], [false, false], [false, false], [false, true]], //L
        [[true, false], [true, false], [true, false], [false, false]], //M
        [[false, false], [false, true], [true, false], [true, false]], //N
        [[true, false], [false, false], [false, false], [false, false]], //O
        [[false, true], [true, false], [true, false], [true, false]], //P
        [[false, false], [false, false], [false, false], [false, false]] //Q
    ],
        dDur: [
        [[true, false], [true, false], [true, false], [true, false]], //A
        [[false, false], [false, false], [false, false], [false, false]], //B
        [[true, false], [true, false], [true, false], [true, false]], //C
        [[false, false], [false, false], [false, false], [true, false]], //D
        [[true, false], [true, false], [true, false], [false, false]], //E
        [[false, false], [true, false], [true, false], [true, false]], //F
        [[true, false], [false, false], [false, false], [false, false]], //G
        [[true, false], [true, false], [true, false], [true, false]], //H
        [[false, false], [false, false], [false, false], [false, false]], //I
        [[true, false], [true, false], [true, false], [true, false]], //J
        [[false, false], [false, false], [true, false], [true, false]], //K
        [[true, false], [true, false], [false, false], [false, false]], //L
        [[true, false], [true, false], [true, false], [true, false]], //M
        [[false, false], [false, false], [false, false], [false, false]], //N
        [[true, false], [true, false], [true, false], [true, false]], //O
        [[false, false], [false, false], [false, false], [true, false]], //P
        [[true, false], [true, false], [true, false], [false, false]] //Q
    ],
        fDur: [
        [[true, false], [true, false], [true, false], [true, false]], //A
        [[false, false], [false, false], [false, true], [true, false]], //B
        [[true, false], [true, false], [false, false], [false, false]], //C
        [[false, true], [true, false], [true, false], [true, false]], //D
        [[false, false], [false, false], [false, false], [false, false]], //E
        [[true, false], [true, false], [true, false], [true, false]], //F
        [[false, false], [false, false], [false, false], [false, true]], //G
        [[true, false], [true, false], [true, false], [false, false]], //H
        [[false, false], [false, true], [true, false], [true, false]], //I
        [[true, false], [false, false], [false, false], [false, false]], //J
        [[true, false], [true, false], [true, false], [true, false]], //K
        [[false, false], [false, false], [false, false], [false, false]], //L
        [[true, false], [true, false], [true, false], [true, false]], //M
        [[false, false], [false, false], [false, true], [true, false]], //N
        [[true, false], [true, false], [false, false], [false, false]], //O
        [[false, true], [true, false], [true, false], [true, false]], //P
        [[false, false], [false, false], [false, false], [false, false]] //Q
    ],
        gDur: [
        [[true, false], [true, false], [true, false], [true, false]], //A
        [[false, false], [false, false], [false, false], [false, false]], //B
        [[true, false], [true, false], [true, false], [true, false]], //C
        [[false, false], [false, false], [true, false], [true, false]], //D
        [[true, false], [true, false], [false, false], [false, false]], //E
        [[true, false], [true, false], [true, false], [true, false]], //F
        [[false, false], [false, false], [false, false], [false, false]], //G
        [[true, false], [true, false], [true, false], [true, false]], //H
        [[false, false], [false, false], [false, false], [true, false]], //I
        [[true, false], [true, false], [true, false], [false, false]], //J
        [[false, false], [true, false], [true, false], [true, false]], //K
        [[true, false], [false, false], [false, false], [false, false]], //L
        [[true, false], [true, false], [true, false], [true, false]], //M
        [[false, false], [false, false], [false, false], [false, false]], //N
        [[true, false], [true, false], [true, false], [true, false]], //O
        [[false, false], [false, false], [true, false], [true, false]], //P
        [[true, false], [true, false], [false, false], [false, false]] //Q
    ]
    }
};



VioLearn.lagen = [
        { // 1. Lage
            shift: 2, // starts at Row 1 -> shift -1 == extendUp Row
            pos: [
                [[0, 0], [0, 0], [0, 0], [0, 0]], // extend Up
                [[0, -1], [0, -1], [0, -1], [0, 1]], // Row 1
                [[0, 1], [1, -1], [1, -1], [1, 1]], // Row 2
                [[1, -1], [1, 2], [1, 2], [1, 2]], // Row 3
                [[2, -1], [2, -1], [2, -1], [2, -1]], // Row 4
                [[2, 3], [2, 3], [2, 3], [2, 3]], // Row 5
                [[3, -1], [3, -1], [3, -1], [3, -1]], // Row 6
                [[3, -1], [3, -1], [-1, -1], [-1, -1]] // extend Down
                ]
        },
        { // 2. Lage
            shift: 4,
            pos: [
                [[0, -1], [0, -1], [0, -1], [0, -1]], // extend Up
                [[0, -1], [0, 1], [0, 1], [0, 1]], // Row 1
                [[1, -1], [1, -1], [1, -1], [1, -1], [1, -1]], // Row 2
                [[1, 2], [1, 2], [1, 2], [1, 2]], // Row 3
                [[2, -1], [2, -1], [2, -1], [2, -1]], // Row 4
                [[2, 3], [2, 3], [3, -1], [3, -1]], // Row 5
                [[3, -1], [3, -1], [3, -1], [3, -1]], // Row 6
                [[-1, -1], [-1, -1], [-1, -1], [-1, -1]]
                ]
        },
        { // 3. Lages
            shift: 5,
            pos: [
                [[-1, -1],  [0, -1],  [0, -1],  [0, -1]], // extend Up
                [[0, -1],   [0, -1],  [0, -1],  [0, -1]], // Row 1
                [[0, 1],    [0, 1],   [0, 1],   [0, 1]], // Row 2
                [[1, -1],   [1, -1],  [1, -1],  [1, -1]], // Row 3
                [[1, 2],    [1, 2],   [2, -1],  [2, -1]], // Row 4
                [[2, -1],   [2, -1],  [2, 3],   [2, 3]], // Row 5
                [[3, -1],   [3, -1],  [3, -1],  [3, -1]], // Row 6
                [[3, -1],   [3, -1],  [3, -1],  [3, -1]] // extend Down
                ]
        },
        { // 4. Lage
            shift: 7,
            pos: [
                [[-1, 0], [-1, 0], [-1, 0], [-1, 0]], // extend Up
                [[0, -1], [0, -1], [0, -1], [0, -1]], // Row 1
                [[0, 1], [0, 1], [1, -1], [1, -1]], // Row 2
                [[1, -1], [1, -1], [1, 2], [1, 2]], // Row 3
                [[2, -1], [2, -1], [2, -1], [2, -1]], // Row 4
                [[2, 3], [2, 3], [2, 3], [2, 3]], // Row 5
                [[3, -1], [3, -1], [3, -1], [3, -1]], // Row 6
                [[3, -1], [3, -1], [3, -1], [-1, -1]] // extend Down
                ]
        },
        { // 5. Lage
            shift: 9,
            pos: [
                [[-1, 0],   [-1, 0], [0, -1], [0, -1]], // extend Up
                [[0, -1],   [0, -1], [0, 1], [0, 1]], // Row 1
                [[1, -1],   [1, -1], [1, -1], [1, -1]], // Row 2
                [[1, 2],    [1, 2], [1, 2], [1, 2]], // Row 3
                [[2, -1],   [2, -1], [2, -1], [2, -1]], // Row 4
                [[2, 3],    [2, 3], [2, 3], [3, -1]], // Row 5
                [[3, -1],   [3, -1], [3, -1], [3, -1]], // Row 6
                [[3, -1],   [-1, -1], [-1, -1], [-1, -1]] // extend Down
                ]
        },
        { // 6. Lage
            shift: 10,
            pos: [
                [[-1, -1], [-1, -1], [-1, 0], [-1, 0]], // extend Up
                [[0, -1], [0, -1], [0, -1], [0, -1]], // Row 1
                [[0, 1], [0, 1], [0, 1], [0, 1]], // Row 2
                [[1, -1], [1, -1], [1, -1], [1, -1]], // Row 3
                [[1, 2], [1, 2], [1, 2], [2, -1]], // Row 4
                [[2, -1], [2, -1], [2, -1], [2, 3]], // Row 5
                [[3, 3], [3, -1], [3, -1], [3, -1]], // Row 6
                [[3, -1], [3, -1], [3, -1], [3, -1]] // extend Down
                ],
        }
    ];

VioLearn.FINGER_ENUM = {
        finger1: 0,
        finger2: 1,
        finger3: 2,
        finger4: 3,
        notUsed: -1
    }

VioLearn.groups = [ // contains the position +lage.shift
    [0, 1, 3, 5], // 1st Group
    [0, 2, 3, 5], // 2nd Group
    [0, 2, 4, 5] // 3rd Group
];

const GROUP_MODE = {
    none: -1,
    group1: 0,
    group2: 1,
    group3: 2
}

COLOR_MARKUP = {
   color0: 0,
   color1: 1,
   color2: 2,
   color3: 3,
   helpLines: 4,
   none: 5,
   color: [0, 1, 2, 3]
}
VioLearn.helpLines = [2, 4, 5, 7, 9, 10, 12, 14, 15];

const COLOR_MARKUP_FINGERBOARD = {
   none: 0,
   helpLines: 1,
   cDur: 2,
   bDur: 3,
   dDur: 4,
   fDur: 5,
   gDur: 6,
}
/*
VioLearn.colorMarkup = [
  {
    color: COLOR_MARKUP.color1,
    row: 2
  }, {
    color: COLOR_MARKUP.color2,
    row: 4
  }, {
    color: COLOR_MARKUP.color3,
    row: 5
  }, {
    color: COLOR_MARKUP.color1,
    row: 7
  }, {
    color: COLOR_MARKUP.color2,
    row: 9
  }, {
    color: COLOR_MARKUP.color3,
    row: 10
  }, {
    color: COLOR_MARKUP.color1,
    row: 12
  }, {
    color: COLOR_MARKUP.color2,
    row: 14
  }, {
    color: COLOR_MARKUP.color3,
    row: 15
  }];

VioLearn.colorMarkup_getColor = function (row) {
  for(let i=0, max=VioLearn.colorMarkup.length;i<max;i++) {
    if(VioLearn.colorMarkup[i].row == row)
      return VioLearn.colorMarkup[i].color;
  }
  return false;
}
*/


VioLearn.settings = {};
//VioLearn.settings.lagenColor = ["#ff0000", "#ffff00", "#00ffff", "#ff00ff", "#00ff00", "#0000ff"];
VioLearn.settings.lageLength = 6;
VioLearn.settings.maxNameNumForPos = 2;
VioLearn.settings.defaultShowFinger = [1, 0, 2, 1, 0, 2, 1]

console.log(VioLearn);



// ENUMERATIONS

const OUTPUT_MODE = {
    picAndLetter: 0,
    pic: 1,
    letter: 2,
    mix: 3
};
const FINGER_MODE = {
    click: 0,
    drop: 1,
    clickOnFinger: 2,
    fingerHoldAndClick: 3
};


const NOTE_SHOW_FINGER_MODE = {
    none: 0,
    show: 1,
    mix: 2,
    showWhenLageChange: 3
}



const TONALITIES = {
  cDur: "cDur",
  bDur: "bDur",
  dDur: "dDur",
  fDur: "fDur",
  gDur: "gDur",
  invCdur: "invCdur",
  whole: "whole"
}

const GAME_MODE = {
    notSet: -1,
    findNote: 0,
    multipleFingerBoard: 1,
    multipleNoteBoard: 2,
    playPiece: 3
}

const TYP_SHOW_MATRIX = {
  point: true,
  name: false
}

const NOTE_TYP = {
  fingerBoard: 0,
  noteBoard: 1,
  normal: 2
}

const END_CONDITON_TYPE = {
   rightOnes: 0,
   wrongOnes: 1,
   rightOnesInARow: 2,
   wrongOnesInARow: 3,
   duration: 4,
   timeUntilNextMove: 5
}

const BRONZE = [
   {fingerMode: FINGER_MODE.click, time: 90},
   {fingerMode: FINGER_MODE.drop, time: 120},
   {fingerMode: FINGER_MODE.clickOnFinger, time: 90},
   {fingerMode: FINGER_MODE.fingerHoldAndClick, time: 90},
];

const SILVER = [
   {fingerMode: FINGER_MODE.click, time: 75},
   {fingerMode: FINGER_MODE.drop, time: 105},
   {fingerMode: FINGER_MODE.clickOnFinger, time: 75},
   {fingerMode: FINGER_MODE.fingerHoldAndClick, time: 75},
];

const GOLD = [
   {fingerMode: FINGER_MODE.click, time: 60},
   {fingerMode: FINGER_MODE.drop, time: 90},
   {fingerMode: FINGER_MODE.clickOnFinger, time: 60},
   {fingerMode: FINGER_MODE.fingerHoldAndClick, time: 60},
];
