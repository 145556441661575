class SettingsProvider extends Publisher {
   constructor(arg) {
      super();

      //this.url = "https://configure.violearn.at/PHP/Level/index.php";
      this.url = "/PHP/Level/index.php";
      let getterVars = {
         isLoading: false,
         ID: -1
      }

      // erzeugt zu den Vars jeweils eine GET und SET Methode
      let privateVars = {

         //Noten im Spiel
         lagen: [],
         group: GROUP_MODE.none,
         tonality: TONALITIES.whole,
         basicTone: true,

         //Geige
         rotation: 0,
         showHelpLines: true,
         showFinger: true,
         showCssDur: true,
         colorMarkupPoints: COLOR_MARKUP_FINGERBOARD.none,

         numNotesFingerBoard: 5,
         playingLageAuto: true,
         fingerMode: FINGER_MODE.click,

         //Notenzeile
         outputMode: OUTPUT_MODE.picAndLetter,
         numNotesNoteBoard: 5,
         showFingerNum: NOTE_SHOW_FINGER_MODE.none,

         //Spieltyp
         notesTurnable: true,
         defaultShowNames: true,
         tippsActive: true,
         showTippAfterNumWrongOnes: "2",
         gameMode: GAME_MODE.findNote,


         //Konditionen
         levelCondition: [],
         bronzeCondition: [],
         silverCondition: [],
         goldCondition: [],
         endCondition: [{ // PlayModes: duration, rightOnes, wrongOnes, rightOnesInARow, wrongOnesInARow, timeUntilNextMove      // duration is always the first in the array
            win: true,
            type: END_CONDITON_TYPE.rightOnes,
            number: 5
         }, {
            win: false,
            type: END_CONDITON_TYPE.wrongOnes,
            nubmer: 5
         }]

      };

      this.settings = null;

      this._vars = privateVars;

      for (let key in privateVars) {
         this["_" + key] = (arg != undefined && arg[key] != undefined) ? arg[key] : privateVars[key];


         Object.defineProperty(this, key, {
            set: function(obj) {
               this._set(obj, "_" + key);
            },
            get: function() {
               return this["_" + key];
            }
         });
      }

      for (let key in getterVars) {
         this["_" + key] = (arg != undefined && arg[key] != undefined) ? arg[key] : getterVars[key];
         console.log("SETTINGS::set", key, this["_" + key], arg, getterVars);


         Object.defineProperty(this, key, {
            get: function() {
               return this["_" + key];
            }
         });
      }

      console.log("INIT Settings with data", this);

      this._init = false;
   }

   isInit() {
      return this._init;
   }


   init(level) {
      console.log("SETTINGS_init ... -----------------------", level, window.location.href);
      const queryString = window.location.href;
      if(level == undefined || level < 1 || !Number.isInteger(level)) {
         level = queryString.split("/");
         level = level[level.length-1]-0;

         // wenn das Game noch nicht geladen wurde ...
         if(!Number.isInteger(level)) {
            return false;
         }
      }

      if(this._isLoading == true && this._ID == level)
         return true;

      console.log("Settings::INIT Level: "+level);
      this._ID = level;
      this._loadSettings(level);
      this._init = true;
   }

   saveStat(stat) {
      console.log("SETTINGS::saveStat", this._fingerMode, GOLD.filter(elem => elem.fingerMode == this._fingerMode), stat.playTime);

      if(GOLD.filter(elem => elem.fingerMode == this._fingerMode)[0].time >= stat.playTime && stat.win) {
         status = "GOLD";
      } else if(SILVER.filter(elem => elem.fingerMode == this._fingerMode)[0].time >= stat.playTime && stat.win) {
         status = "SILVER";
      } else if(BRONZE.filter(elem => elem.fingerMode == this._fingerMode)[0].time >= stat.playTime && stat.win) {
         status = "BRONZE";
      } else if(stat.win){
         status = "LEVELUP";
      } else {
         status = "UNLOCKED";
      }

      stat.playTime = toHHMMSS(stat.playTime);

      if(this.ID != null) {
         console.log("SETTING::saveStat", {playedLevel: {levelID: this.ID, status: status, ...stat}})

      $.post(this.url, {playedLevel: {levelID: this.ID, status: status, ...stat}}, function () {}, "json");
      }

      this._fire(status, "_medal");
   }

   _loadSettings(level) {
      this._isLoading = true;
      this._fire(true, "_isLoading");
      console.log("SETTINGS::load", this.url);
      $.post(this.url, {getLevel: level}, function(context = this) {
         return function (data) {
            console.log("LOAD SETTINGS ------------------------------------------------", data);
         if(data.getLevel) {
            for(let key in data.getLevel) {
               if(context._isset(key)) {
                  // convert number Strings to number values
                  var reg = new RegExp('^[0-9]+$');
                  if(reg.test(data.getLevel[key])) {
                     data.getLevel[key] -= 0;
                  }

                  if(data.getLevel[key] == "false")
                     data.getLevel[key] = false;
                  if(data.getLevel[key] == "true")
                     data.getLevel[key] = true;

                  if(key == "lagen" && !Array.isArray(data.getLevel[key]))
                     data.getLevel[key] = [data.getLevel[key]];

                  context[key] = data.getLevel[key];
               } else if(key == "ID") {
                  context._ID = data.getLevel.ID;
                  context._fire(context.ID, "_ID");
               } else {
                  console.log("SETTINGS NO ENTRY FOUND FOR KEY ", key);
               }
            }
            console.log("new SETTINGS", context);
            context._isLoading = false;
            context._fire(false, "_isLoading");
         } else {
            alert("Es ist ein Fehler aufgetreten");
         }
      }}(this), "json");
   }
   _isset(key, inPrivateVars = true) {
      if(inPrivateVars == true) {
         return this._vars[key] != undefined;
      }

      if(this['_'+key] != undefined)
         return true;
      return false;
   }

   _fire(arg, action) {
      console.log("SETTINGS::FIRE" + action, arg);
      super._fire(arg, "SETTINGS" + action);
   }

// kann auskommentiert werden? sollte alles mit der Load funktion geladen werden ...
   _set(arg, varName) {
      console.log("SeTTINGS::SET", arg, varName)
      if (arg != this[varName]) {
         this[varName] = arg;
         this._fire(this[varName], varName);
      }
   }
   /*
      set lagen(arg) {_set(arg, "_lagen");}
      set group (arg {_set(arg, "_group");}
      set tonality(arg) {_set(arg, "_tonality");}
      set basicTone(arg) {_set(arg, "_basicTone");}
      set playingLageAuto(arg) {_set(arg, "_playingLageAuto");}
      set notesTurnable(arg) {_set(arg, "_notesTurnable");}
      set defaultShowNames(arg) {_set(arg, "_defaultShowNames");}
      set tippsActive(arg) {_set(arg, "_tippsActive");}

      get lagen() {return this._lagen;}
      get group() {return this._group;}
      get tonality() {return this._tonality;}
      get basicTone() {return this._basicTone;}
      get playingLageAuto() {return this._playingLageAuto;}
      get notesTurnable() {return this._notesTurnable;}
      get defaultShowNames() {return this._defaultShowNames;}
   */
   /*
   sollte nicht mehr verwendet werden - da inputs direkt ausgelesen werden
   */
   observe(arg, action) {
      switch (action) {
         case "newLage":
            this.lagen = arg;
            break;
         case "newGroup":
            this.group = arg;
            break;
         case "newTonality":
            this.tonality = arg;
            break;
         case "newBasicTone":
            this.basicTone = arg;
            break;
         case "newSetPlayingLageAuto":
            this.playingLageAuto = arg;
            break;
         case "newNotesAreTurnable":
            this.notesTurnable = arg;
            break;
         case "newDefaultShowNames":
            this.defaultShowNames = arg;
            break;
         case "newShowTipps":
            this.tippsActive = arg;
            break;
         case "newFingerMode":
            this.fingerMode = arg;
            break;
         case 'newNumNotesFingerBoard':
            this.numNotesFingerBoard = arg;
            break;
         case "newRotation":
            this.rotation = arg;
            break;
         case "newShowHelpLines":
            this.showHelpLines = arg;
            break;
         case "newShowFinger":
            this.showFinger = arg;
            break;
         case "newShowCssDur":
            this.showCssDur = arg;
            break;
         case "newColorMarkupPoints":
            this.colorMarkupPoints = arg;
            break;
         case "newGameMode":
            this.gameMode = arg;
            break;
         case 'newOutputMode':
            this.outputMode = arg;
            break;
         case 'newNumNotesNoteBoard':
            this.numNotesNoteBoard = arg;
            break;
         case "showNoteBoardFingerNumber":
            this.showFingerNum = arg;
            break;
         case "newShowTippAfterNumWrongOnes":
              this.showTippAfterNumWrongOnes = arg;
              break;
      }
   }
}
