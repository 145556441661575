// JavaScript Document
'use strict';

class Game extends Publisher {
    constructor() {
        super();


        this.settings = new SettingsProvider();


        this._getViewInput = new GetViewInput();
        this._inputListener = new InputListener();
        this._fingerBoard = new FingerBoard({settings: this.settings});
        this._fingerBoardPresentation = new FingerBoardPresentation({settings: this.settings});
        this._noteBoard = new NoteBoard({settings: this.settings});


        this._game = null;


        this._stats = new GameStatistic();
        this._gameBoard = new GameBoard();

        this._view = new View();
        this._isPlaying = false;
        this._pause = false;


        this.subscribe(this.settings.observe, this.settings);
        this.subscribe(this._view.observe, this._view);
        this.subscribe(this._fingerBoard.observe, this._fingerBoard);
        this.subscribe(this._noteBoard.observe, this._noteBoard);
        this.subscribe(this._stats.observe, this._stats);
        this.subscribe(this._gameBoard.observe, this._gameBoard);
        this.subscribe(this._getViewInput.observe, this._getViewInput);
        this.subscribe(this._fingerBoardPresentation.observe, this._fingerBoardPresentation);

        this.settings.subscribe(this._fingerBoardPresentation.observe, this._fingerBoardPresentation);
        this.settings.subscribe(this._fingerBoard.observe, this._fingerBoard);
        this.settings.subscribe(this.observe, this);
        this.settings.subscribe(this._noteBoard.observe, this._noteBoard);
        this.settings.subscribe(this._stats.observe, this._stats);



        this._fingerBoard.subscribe(this._view.observe, this._view);
        this._fingerBoard.subscribe(this._noteBoard.observe, this._noteBoard);
        this._fingerBoard.subscribe(this._fingerBoardPresentation.observe, this._fingerBoardPresentation);

        this._fingerBoardPresentation.subscribe(this._view.observe, this._view);

        this._inputListener.subscribe(this.settings.observe, this.settings);
        this._inputListener.subscribe(this._fingerBoard.observe, this._fingerBoard, "FingerBoard");
        this._inputListener.subscribe(this.observe, this);
        this._inputListener.subscribe(this._noteBoard.observe, this._noteBoard);
        this._inputListener.subscribe(this._gameBoard.observe, this._gameBoard);
        this._inputListener.subscribe(this._getViewInput.observe, this._getViewInput);
        this._inputListener.subscribe(this._view.observe, this._view);
        this._inputListener.subscribe(this._fingerBoardPresentation.observe, this._fingerBoardPresentation);

        this._noteBoard.subscribe(this._view.observe, this._view);
        this._noteBoard.subscribe(this._fingerBoard.observe, this._fingerBoard);

        this._stats.subscribe(this._view.observe, this._view);

        this._gameBoard.subscribe(this._view.observe, this._view);
        this._gameBoard.subscribe(this._getViewInput.observe, this._getViewInput);

        this._getViewInput.subscribe(this._gameBoard.observe, this._gameBoard);
        this._getViewInput.subscribe(this._stats.observe, this._stats);
        this._getViewInput.subscribe(this.observe, this);


        this._getViewInput.observe(true, "getEndCondition");

        this.initGameType(GAME_MODE.findNote);



        this._noteBoard.observe(5, "newBoardNumNotes");
        //this._fire(true, "stopGame");

        //this._gameBoard.init();
        this._gameBoard.observe(true, 'setGameFieldMiddle');
        console.log("GAME::INIT Settings");
    }

    _unsubscribeGameType(type) {
        if(this._game != null) {
            this._game.unsubscribe(this._stats.observe);
            this._game.subscribe(this._noteBoard.observe);
            this._game.subscribe(this.observe);

            this.unsubscribe(this._game.observe);
            this._getViewInput.unsubscribe(this._game.observe);
            this._fingerBoard.unsubscribe(this._game.observe);
            this._inputListener.unsubscribe(this._game.observe);
            this._noteBoard.unsubscribe(this._game.observe);
        }
    }

    initGameType() {
      console.log("GAME::initGameType", this.settings.gameMode);
        this._unsubscribeGameType();
        let gameBuilder = new GameBuilder(this.settings);
        if(this._game != null) {
            this._game.destroy();
        }
        this._game = gameBuilder.game;
      // sendet Events an
      this._game.subscribe(this._stats.observe, this._stats);
      this._game.subscribe(this._noteBoard.observe, this._noteBoard);
      this._game.subscribe(this._fingerBoard.observe, this._fingerBoard);
      this._game.subscribe(this.observe, this);
      // empfängt Events von
      this.subscribe(this._game.observe, this._game);
      this._getViewInput.subscribe(this._game.observe, this._game);
      this._fingerBoard.subscribe(this._game.observe, this._game);
      this._inputListener.subscribe(this._game.observe, this._game);
      this._noteBoard.subscribe(this._game.observe, this._game);
      this.settings.subscribe(this._game.observe, this._game);
      this._game.init();

       this._fire(this.settings.endCondition, "newEndCondition");
    }

    startGame() {


        let isPlaying = this._isPlaying;
        let pause = this._pause;

        if(isPlaying == false) {
            this._isPlaying = true;
            this._pause = false;
            this._fire(true, "startGame");
            this._fire(true, "GAME_start");
            console.log("GAME::FIRE startGame");
        } else {  // Pause ...
            this._pause = !pause;
            this._fire(this._pause, "pauseGame");
            this._fire({pause: this._pause, playTime: this._stats.getCurrPlayTime()}, "GAME_pause");
            console.log("GAME::FIRE pauseGame", this._pause);
        }
    }

    isPlaying() {
      return this._isPlaying && this._pause == false;
   }

    stopGame(stat) {

      if(stat != undefined && stat.win != undefined) {
         const playStat = {
            playTime: this._stats.getCurrPlayTime(),
            win: stat.win
         }
         this.settings.saveStat(playStat);
      }



        console.log("GAME::FIRE stopGame");
        this._fire(true, "stopGame");
        this._fire({playTime: this._stats.getCurrPlayTime()}, "GAME_stop");
        this._isPlaying = false;
        this._pause = false;

        // the stop button was clicked -> game will be stopped and reseted
        if(stat == undefined) {
           this._fire(true, "GAME_reset");
           return true;
        }

    }

    observe(arg, action) {
        //console.log("Game::observe", arg, action);

        switch(action) {
            case 'clickStartGame':
                this.startGame();
                break;
            case 'clickStopGame':
                this.stopGame();
                break;
            case "fireGameEnd":
                console.log("stop");
                this.stopGame(arg);
                break;
            case "newLage":
                //checkNoteEqual();
                break;
            case "SETTINGS_gameMode":
                this.initGameType(arg);
                break;
            case "SETTINGS_endCondition":
                this._endCondition = arg;
                break;
        }

    }
}
