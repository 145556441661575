//ObjectExtentions.js

jQuery.fn.cssNumber = function(prop) {
   var v = parseInt(this.css(prop), 10);
   return isNaN(v) ? 0 : v;
};

Date.prototype.format = function (format) {

    if (typeof format == "undefined") {
        format = "we d.m.y H:M";
    }

    days = ["SO", "MO", "DI", "MI", "DO", "FR", "SA"];
    year = this.getYear();
    if (year < 999)
        year += 1900;

    month = this.getMonth() + 1;
    if (month < 10)
        month = "0" + month;

    date = this.getDate();
    if (date < 10)
        date = "0" + date;

    hours = this.getHours();
    if (hours < 10)
        hours = "0" + hours;

    minutes = this.getMinutes();
    if (minutes < 10)
        minutes = "0" + minutes;

    return format.replace("d", date).replace("m", month).replace("y", year).replace("H", hours).replace("M", minutes).replace("we", days[this.getDay()]);
}

Date.prototype.toString = function () {
    days = ["SO", "MO", "DI", "MI", "DO", "FR", "SA"];
    year = this.getYear();
    if (year < 999)
        year += 1900;

    date = this.getDate();
    if (date < 10)
        date = "0" + date;

    hours = this.getHours();
    if (hours < 10)
        hours = "0" + hours;

    minutes = this.getMinutes();
    if (minutes < 10)
        minutes = "0" + minutes;

    if (hours == 0 && minutes == 0)
        return days[this.getDay()] + " " + date + "." + (this.getMonth() + 1) + "." + year;
    return days[this.getDay()] + " " + date + "." + (this.getMonth() + 1) + "." + year + " " + hours + ":" + minutes;
}

/*

*/
Date.prototype.decode = function (str) {
    if (typeof str != "string")
        return false;

    if (str.split("-").length == 3) {
        this.setYear(str.split("-")[0]);
        var d = str.split("-")[1] - 1;
        this.setDate(str.split("-")[2].split(" ")[0]);
        this.setMonth(d); // this order because if your current date is 30.1.2012 and the Month is set to February it automatically chances it to March because the 30.2. don't exist :)
        this.setDate(str.split("-")[2].split(" ")[0]);
        if (str.split(" ").length == 2) {
            this.setHours(str.split(" ")[1].split(":")[0]);
            this.setMinutes(str.split(":")[1]);
            this.setSeconds(str.split(":")[2]);
            this.setMilliseconds(0);
        }
    } else if (str.split(".").length == 3) {
        this.setYear(str.split(" ")[1].split(".")[2]);
        this.setDate(str.split(" ")[1].split(".")[0]);
        this.setMonth(str.split(" ")[1].split(".")[1]);
        this.setDate(str.split(" ")[1].split(".")[0]);
        this.setHours(str.split(" ")[2].split(":")[0]);
        this.setMinutes(str.split(" ")[2].split(":")[1]);
        this.setSeconds(00);
        this.setMilliseconds(0);
    } else
        return false;
}

Date.prototype.getTimestamp = function () {
    year = this.getYear();
    if (year < 999)
        year += 1900;

    date = this.getDate();
    if (date < 10)
        date = "0" + date;

    hours = this.getHours();
    if (hours < 10)
        hours = "0" + hours;

    minutes = this.getMinutes();
    if (minutes < 10)
        minutes = "0" + minutes;

    seconds = this.getSeconds();
    if (seconds < 10)
        seconds = "0" + seconds;

    return year + "-" + (this.getMonth() + 1) + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
}

function arrayContains(array, element) {
    for (var i = 0; i < array.length; i++) {
        if (array[i] == element) {
            return true;
        }
    }
    return false;
}

function arrayUnique(arrayName) {
    var newArray = new Array();
    label: for (var i = 0; i < arrayName.length; i++) {
        for (var j = 0; j < newArray.length; j++) {
            if (newArray[j] == arrayName[i])
                continue label;
        }
        newArray[newArray.length] = arrayName[i];
    }
    return newArray;
}

function dump(arr, level) {
    var dumped_text = "";
    if (!level) level = 0;

    //The padding given at the beginning of the line.
    var level_padding = "";
    for (var j = 0; j < level + 1; j++) level_padding += "    ";

    if (typeof (arr) == 'object') { //Array/Hashes/Objects
        for (var item in arr) {
            var value = arr[item];

            if (typeof (value) == 'object') { //If it is an array,
                dumped_text += level_padding + "'" + item + "' ...\n";
                dumped_text += dump(value, level + 1);
            } else {
                dumped_text += level_padding + "'" + item + "' => \"" + value + "\"\n";
            }
        }
    } else { //Stings/Chars/Numbers etc.
        dumped_text = "===>" + arr + "<===(" + typeof (arr) + ")";
    }
    return dumped_text;
}

const toHHMMSS = function (sec_num) {
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
}

String.prototype.trim = function () {
    return this.replace(/^\s+|\s+$/g, '');
}

function arrayClone(array) {
    var arr1 = new Array();
    for (var property in array) {
        arr1[property] = typeof (array[property]) == 'object' ? array[property].clone() : array[property]
    }
    return arr1;
}

Array.prototype.pushIfNotExists = function (element) {
    if (!this.includes(element)) {
        this.push(element);
    }
}

Array.prototype.isEqual = function (array) {
    return (this.length == array.length) && this.every(function (element, index) {
        return element === array[index];
    });
}

function getRandomInt(max, min=0) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}
