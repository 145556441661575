// Javascript Document
'use strict';

class GetViewInput extends Publisher {
    constructor() {
        super();
        this.debug = false;
    }


    _fireGameFieldAndBordDimensions() {
      console.log("GETVIEWINPUT::_fireGameFieldAndBordDimensions", $(".gameField").height(), $(".gameBoard").height(), $(document).height(), $(window).height(), $("body").height());
        let gameField = {
            left: $(".gameField").cssNumber("left"),
            top: $(".gameField").cssNumber("top"),
            width: $(".gameField").width(),
            height: $(".gameField").height()
        };
        let gameBoard = {
            width: $(".gameBoard").width(),
            height: $(".gameBoard").height()
        }

        gameField.bottom = gameBoard.height - gameField.height - gameField.top;
        gameField.right = gameBoard.width - gameField.width - gameField.left;

        let obj = {
            gameField: gameField,
            gameBoard: gameBoard
        };
        this._fire(obj, "newGameFieldAndBordDimensions");
        console.log("GETVIEWINPUT_newGameFieldAndBordDimensions",obj);
        this._fire(obj, "GETVIEWINPUT_newGameFieldAndBordDimensions");
        return obj;
    }


    _fireEndCondition() {
        let jDiv = $(".endConditions div");

        let json = "[";
        for (let i = 0; i < jDiv.length; i++) {
            if (i > 0)
                json += ", ";
            json += "{" + jDiv.eq(i).data("condition") + "}";
        }
        json += "]";

        let obj = JSON.parse(json);
        this._fire(obj, "newEndCondition");
    }


    observe(arg, action) {
        if (this.debug)
            console.log("GetViewInput::observe", arg, action);

        switch (action) {
           case 'GAME_start':
               this._fireGameFieldAndBordDimensions();
               break;
            case "getGameFieldAndBordDimensions":
                this._fireGameFieldAndBordDimensions();
                break;
            case "getEndCondition":
                this._fireEndCondition();
                break;

        }
    }

}
