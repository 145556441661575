// JS Code
'use strict';

/*
Fires:
- newCurrendNot
*/
class NoteBoard extends Publisher {

  constructor(arg) {
    super();

    this.settings = arg.settings;

    this.debug = false;
    this._isPlaying = false;
    this._playableNotes = [];
    this._notes = [];

    this._allDifferent = false;
    this._fireCurrentNote = false;
    this._currentNote = -1;
    this._allowClick = false;
  }


  start() {
    this._isPlaying = true;
    this._fire(this._notes, "printNoteBoard");
    if (this._fireCurrentNote)
      this._fire(true, "printFrameNoteBoard");
    if (this._fireCurrentNote) {

    }
  }

  pause(isPlaying = !this._isPlaying) {
    // stop the click Events form happening
    this._isPlaying = isPlaying;
  }

  stop() {
    this._isPlaying = false;
    this._fire({}, "printNoteBoard");
    this.generateNewNotes();
  }
/*
// FEHLER BEHOBEN - TESTAUFBAU NICHT MEHR IN VERWENDUNG
  testNote() {
     console.log(" ");
     console.log(" ");
     console.log(" ");
     console.log(" ");
     console.log("TEST NOTE cis´´ finger: 0, lage: 1, row:11 column:1")
     //getRandomNoteClone
     let note = {};
     while(note.name != 'cis´´') {
        note = this._playableNotes.getRandomNoteClone(NOTE_TYP.noteBoard);
    }
    console.log("1: getRandomNoteClone", note);

    let note2 = note.clone();

    note2.setXY(this._playableNotes.getPosToName(note.name))

    console.log("2: setXY()", note2);

    let note3;
    let ret;
    for(let i=0;i<3;i++) {
      note3 = note2.clone();
      ret = this._playableNotes.setLageIfPossible(note3, i);
      console.log("3."+i+" setLageIfPossible", ret, i, note3);
   }

   let note4;

for(let i=0;i<1000;i++) {
   note4 = note2.clone();
   this._playableNotes.setFingerRandom(note4);
   if(note4.integrity == false) {
      console.log("4. setFingerRandom -- FEHLER", note4);
      break;
   }
}
    console.log(" ");
    console.log(" ");
}*/

  _getNewRandomNote(outputMode = this.settings.outputMode, playableNotes = this._playableNotes, noteBeforeIndex = -1) {
    var note = playableNotes.getRandomNoteClone(NOTE_TYP.noteBoard);

    // set all possible XY Position innerhalb der playable Notes
    note.setXY(this._playableNotes.getPosToName(note.name));
    //console.log("NOTEBOARD::_getNewRandomNote", outputMode, this.settings.showFingerNum)

    // setze Finger je nach FINGER_MODE
    if (this.settings.showFingerNum == NOTE_SHOW_FINGER_MODE.show) {
      this._playableNotes.setFingerRandom(note);
      note.showFingerNum = true;
    } else if (this.settings.showFingerNum == NOTE_SHOW_FINGER_MODE.mix) {
      let r = getRandomInt(2);
      if (r == 1) {
        this._playableNotes.setFingerRandom(note);
        note.showFingerNum = true;
      }
    } else if (this.settings.showFingerNum == NOTE_SHOW_FINGER_MODE.showWhenLageChange) {
      if (noteBeforeIndex != -1) {
        let noteBefore = this._notes[noteBeforeIndex];
        if (!this._playableNotes.setLageIfPossible(note, noteBefore.lage)) {
          this._playableNotes.setFingerRandom(note);
          note.showFingerNum = true;
        }

      } else {
        this._playableNotes.setFingerRandom(note);
        note.showFingerNum = true;
      }

    }

    /*
        // set Multiple XY Positions if no finger and Lage are selected
        if(!note.hasFinger()) {
          let pos = NOTES.getPosToName(note.name);
          if(pos.length > 1)
            note.setXY(pos);
        }
*/
    if (outputMode == OUTPUT_MODE.mix) {
      note.outputMode = getRandomInt(3);
    } else {
      note.outputMode = this.settings.outputMode;
    }
    return note;
  }

  // generiert alle Noten neu
  generateNewNotes(mustContainNote = this._currentNote, allDifferent = false, fireCurrentNote = this._fireCurrentNote, outputMode = this.settings.outputMode, numNotes = this.settings.numNotesNoteBoard, playableNotes = this._playableNotes) {
     const debugFunction = false;

    // if (this.debug)
    (debugFunction || this.debug)? console.log("NOTEBOARD::generateNewNotes", outputMode, numNotes, playableNotes): true;



    if (playableNotes.length == 0)
      return false;

    this._notes = [];

    let noName = false; // contains the name of the note that is set
    if (mustContainNote != -1) {
      let otherName = mustContainNote._playableNotes.getOtherPossibleName(mustContainNote);
      if (otherName != false) {
        noName = [otherName, mustContainNote.name];
      } else {
        noName = mustContainNote.name;
      }
    }

    (debugFunction)? console.log("NOTEBOARD::generateNewNotes numNotes = "+numNotes) : true;
    for (let i = 0; i < numNotes; i++) {

      this._notes[i] = this._getNewRandomNote(outputMode, playableNotes, i - 1);

       (debugFunction)? console.log("NOTEBOARD::generateNewNotes", i, this._notes): true;

      if (allDifferent) {
         //check if all notes are different
        for (let j = 0; j < i; j++) {
          if (this._notes[i].softEqualVar(this._notes[j].name, "name")) {
            i--;
            break;
          }
        }
      }
      //there should be no name with the givien noName name from the mustHaveNote
      if (noName != false && this._notes[i].softEqualVar(noName, "name")) {
        i--;
      }
    }

    if (mustContainNote != -1) {
      if (outputMode == OUTPUT_MODE.mix) {
        mustContainNote.outputMode = getRandomInt(3);
      } else {
        mustContainNote.outputMode = this.settings.outputMode;
      }
      this._notes[getRandomInt(numNotes)] = mustContainNote;
    }


    //if (this.debug)
      console.log("NOTEBOARD::generateNewNotes END", this._notes);
    if (this._isPlaying)
      this._fire(this._notes, "printNoteBoard");
    if (this._fireCurrentNote)
      this._fire(true, "printFrameNoteBoard");
    if (fireCurrentNote) {
      this._fire(this._notes[0], "newCurrentNote");
    }
  }
  //löscht die erste Note und hängt hinten eine neue Note daran
  next() {
    let newNote = this._getNewRandomNote(this._outputMode, this._playableNotes, this._notes[this._notes.length]);
    console.log("GENERATE_NEW_NOTE", newNote)
    //console.log("NewNote", newNote);
    this._notes.splice(0, 1);
    if (this._isPlaying)
      this._fire(true, "printRemoveFirstToneFromNoteBoard");
    this._notes.push(newNote);
    this._fire(this._notes[0], "newCurrentNote");
    if (this._isPlaying)
      this._fire(newNote, "printAddToneToNoteBoard");

  }

  set fireCurrentNote(arg) {
    this._fireCurrentNote = arg;
    this._fire(arg, "printFrameNoteBoard");
    if (!arg)
      this._currentNote = -1;
    if (arg && this._notes[0] != undefined) {
      this._fire(this._notes[0], "newCurrentNote");
    }
  }

  showTipp(note) {
    for (let i = 0; i < this.settings.numNotesNoteBoard; i++) {

      if (this._notes[i]._checkEqual(this._notes[i].name, note.name, "name")) {
        this._fire(i, "printHighlightNoteBoard");
      }
    }
  }

  observe(arg, action) {
    if (this.debug)
      console.log("NoteBoard::observe", arg, action);
    switch (action) {
      case 'newPlayableNotes':
        this._playableNotes = arg;
        this.generateNewNotes();
        break;
      case "SETTINGS_numNotesNoteBoard":
      case "SETTINGS_outputMode":
        this.generateNewNotes();
        break;
      case "SETTINGS_showFingerNum":
        if (this.settings.showFingerNum && this._currentNote != -1) {
          this._currentNote.showFingerNum = true;
          this._playableNotes.setFingerRandom(this._currentNote);
        }
        this.generateNewNotes();
        break;
      case "startGame":
        this.start();
        break;
      case "pauseGame":
        this.pause(!arg);
        break;
      case "stopGame":
        this.stop();
        break;
      case "nextNoteInNoteBoard":
        this.next();
        break;
      case "fireCurrentNoteNoteBoard":
        this.fireCurrentNote = arg;
        break;
      case "setNewMultipeNoteBoard":
        this._currentNote = arg;
        if (this.settings.showFingerNum == NOTE_SHOW_FINGER_MODE.show) {
          this._playableNotes.setFingerRandom(this._currentNote);
          this._currentNote.showFingerNum = true;
        }
        this.generateNewNotes(this._currentNote, true);
        break;
      case "showTippNoteBoard":
        this.showTipp(arg);
        break;
      case "unlockClick_NoteBoard":
        if (true != this._allowClick) {
          this._allowClick = true;
        }
        break;
      case "inhibitClick_NoteBoard":
        if (false != this._allowClick) {
          this._allowClick = false;
        }
        break;
    }

    if (this._isPlaying) {
      switch (action) {
        case "clickImgNoteBoard":
          if (this._allowClick)
            this._fire(this._notes[arg], "clickNoteNoteBoard");
          else
            this._fire({}, "clickNoteNoteBoard");
          break;
      }
    }
  }
}
