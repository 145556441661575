// Javascript Document
'use strict';

class View extends Publisher {
    constructor() {
      super();
        this.debug = false;

        this._noteBoard = new View_NoteBoard();
        this._gameBoard = new View_GameBoard();
      }


    _printStatElement(key, stat) {
      if(stat[key] != undefined) {
        $(".gameStats div."+key).show();
        $(".gameStats label."+key).html(stat[key]);
      } else {
        $(".gameStats div."+key).hide();
      }
    }

    printStat(stat) {
        if (this.debug)
            console.log("View::printStat");
        if(stat.win)
            $(".gameStats .hasWon").html("Gewonnen");
        else
            $(".gameStats .hasWon").html("Verloren");

        this._printStatElement("rightOnes", stat);
        this._printStatElement("wrongOnes", stat);
        this._printStatElement("wrongOnesInARow", stat);
        this._printStatElement("rightOnesInARow", stat);
        this._printStatElement("duration", stat);
        this._printStatElement("timeUntilNextMove", stat);
        /*
        $(".gameStats .rightOnes").html(stat.rightOnes);
        $(".gameStats .wrongOnes").html(stat.wrongOnes);
        $(".gameStats .wrongOnesInARow").html(stat.wrongOnesInARow);
        $(".gameStats .rightOnesInARow").html(stat.rightOnesInARow);
        $(".gameStats .duration").html(stat.duration);
        $(".gameStats .timeUntilNextMove").html(stat.timeUntilNextMove);
        */
    }





    showStop() {
        $(".breakScreen").hide();
        $(".stopScreen").show();

        this.showFooter();

        let jButton = $(".startGame.toggle");
        if (jButton.html() == "Pause Game") {
            jButton.html("Start Game");
        }

    }

    hideFooter() {
        $("footer").hide();
    }
    showFooter() {
        console.log("show");
        $("footer").show();
    }

    start() {
        let jStop = $(".stopScreen");
        let jPause = $(".breakScreen");

        this.hideFooter();

        jStop.hide();
        jPause.hide();
        $(".startScreen").hide();

        let jButton = $(".startGame.toggle");
        if (jButton.html() == "Start Game") {
            jButton.html("Pause Game");
        }
    }

    showPause(showBool) {

        let jStop = $(".stopScreen");
        let jPause = $(".breakScreen");

        jStop.hide();

        if (showBool) {
            jPause.show();
            let jButton = $(".startGame.toggle");
            if (jButton.html() == "Pause Game") {
                jButton.html("Start Game");
            }
            //this.showFooter();


        } else {
            jPause.hide();
            let jButton = $(".startGame.toggle");
            if (jButton.html() == "Start Game") {
                jButton.html("Pause Game");
            }

            this.hideFooter();
        }

    }

    printGameFieldPos(pos) {
      /*
      console.log("VIEW::printGameFieldPos", pos);
        $(".gameField").css("left", pos.left);
        $(".gameField").css("top", pos.top);
        $(".gameField").css("font-size", (pos.width / 500) + "em");

        if (typeof pos.height != 'undefined') {
            $(".gameField").css("height", pos.height);
            $(".gameField").css("width", pos.width);
        }*/
    }

    printPlayingLage(pLage) {
        $(".currentLageBorder").removeClass("currLage"+($(".currentLageBorder .currentLageNum").html()-1));
        $(".currentLageBorder").addClass("currLage"+pLage);
        $(".currentLageBorder .currentLageNum").html(pLage+1);
    }

    printSetPlayingLageVisible(visibility) {
        if(visibility) {
            $(".setCurrentLage").show();
        } else {
            $(".setCurrentLage").hide();
        }
    }

    printFingersVisible(visible) {
        if(visible)
            $(".fingers").show();
        else
            $(".fingers").hide();
    }



    printHelpLinesVisible(arg) {
        if(arg)
            $(".colorMarkup").show();
        else
           $(".colorMarkup").hide();
    }

    printFingerPos(arg) {
        for(let i=0;i<4;i++) {
            $(".fingers .finger"+i).removeClass("pos0");
            $(".fingers .finger"+i).removeClass("pos1");
            $(".fingers .finger"+i).removeClass("pos2");
            $(".fingers .finger"+i).removeClass("pos3");
            $(".fingers .finger"+i).removeClass("pos4");
            $(".fingers .finger"+i).removeClass("pos5");
            $(".fingers .finger"+i).addClass("pos"+arg[i]);
            }
    }



    printFrameNoteBoard(arg) {
        if(!arg) {
            console.log("REMOVE BORDER");
            $(".currentFrame").removeClass("currentFrame");
        } else {
            $(".notes .note:eq(0)").addClass("currentFrame");
        }
    }

    observe(arg, action) {
        if (this.debug)
            console.log("View::observe", arg, action);

        switch (action) {
            case 'printNewStat':
                this.printStat(arg);
                break;
            case 'stopGame':
                this.showStop();
                break;
            case 'startGame':
                this.start();
                break;
            case 'pauseGame':
                this.showPause(arg);
                break;
            case "printGameFieldPos":
                this.printGameFieldPos(arg);
                break;
            case "printPlayingLage":
                this.printPlayingLage(arg);
                break;
            case "printSetPlayingLageVisible":
                this.printSetPlayingLageVisible(arg);
                break;
            case "printFingersVisible":
                this.printFingersVisible(arg);
                break;

            case "printHelpLinesVisible":
                this.printHelpLinesVisible(arg);
                break;
            case "printFingerPos":
                this.printFingerPos(arg);
                break;
            case "printFrameNoteBoard":
                this.printFrameNoteBoard(arg);
                break;
            default:
              this._noteBoard.observe(arg, action);
              this._gameBoard.observe(arg, action);
        }
        //Weiterleitung für Angular Components
        this._fire(arg, action);
    }
}
