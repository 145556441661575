 // JS Code
 'use strict';

 class FingerBoard extends Publisher {
    //settings ist die Service Klasse der Settings
    constructor(arg) {
       super();

       this.settings = arg.settings;

       this._isPlaying = false;

       this._playableNotes = {};

       this._multipleNotes = [];
       this._currentNote = null;
    }

    stop() {
       this._isPlaying = false;

       this._fireResetView();
    }


    initSettings() {
       this.getPlayableNotes();

       if (!this.settings.lagen.includes(this._playingLage)) {
          this.playingLage = lagen[0];
       }
    }

    start() {
       this._isPlaying = true;

       this._fireViewOutput();

    }

    _fireViewOutput() {
       this._playableNotes.show();

       this._fire(true, "printGameFieldCenter");

       if (this.settings.gameMode == GAME_MODE.multipleFingerBoard) {
          this._fire(this._multipleNotes, "printMultipleChoiseFingerBoard");
          this._fire(this._multipleNotes, "PRINT_GAMEBOARD_multiple_notes");
       } else if (this.settings.gameMode == GAME_MODE.multipleNoteBoard) {
          this._fire(this._currentNote, "printMultipleChoiseFingerBoard");
          this._fire([this._currentNote], "PRINT_GAMEBOARD_multiple_notes");
       }
    }

    _fireResetView() {
       this._playableNotes.hide();
       this._playableNotes.resetTurnable();

       this._fire("whole", "printRemoveHighlight");
       this._fire(true, "PRINT_GAMEBOARD_softReset");
    }

    pause(isPlaying = !this._isPlaying) {
      console.log("PAUSE", isPlaying);
       this._isPlaying = isPlaying;
    }
    /*

    Problem: isEqual auf _notes angewendet sollte immer false sein oder etwa nicht?

    */

    getPlayableNotes(lagen, group = -1, tonality = 'whole', playingLage = -1, basicTone = true) {

       if (this.debug)
          console.log("FingerBoard::_getPlayableNotes", lagen, group, tonality);

       //console.log("SET Group", this._group);
       console.log("BUILD PLAYABLE NOTES - lagen: "+this.settings.lagen+" tonality: "+this.settings.tonality);
       let playableNotes = new BuildPlayableNotes({
          lagen: this.settings.lagen,
          group: this.settings.group,
          tonality: this.settings.tonality,
          isTurnable: this.settings.notesTurnable,
          defaultShowNameTurnable: this.settings.defaultShowNames,
          showTippOnFingerBoard: this.settings.tippsActive,
          basicTonesActive: this.settings.basicTone,
       });

       playableNotes.subscribe(this.observe, this, "FORWARD_EVENT");

       /*NOTES.set({
             lagen: this._lagen,
             group: this._group,
             tonality: this.settings.tonality,
             isTurnable: this.settings.notesTurnable,
             tippsActive: this._tippsActive
         });
       let newNotes =   playableNotes;
       NOTES.notes = newNotes;*/

       if (this._playableNotes == {} || !playableNotes.isEqual(this._playableNotes)) {
          this._playableNotes = playableNotes;


          this._fire(this._playableNotes, "newPlayableNotes");
          if (this._isPlaying) {
             this._playableNotes.show();
          }
       }

       //this._refreshTurnMatrix();

       if (this.debug)
          console.log("END FingerBoard::_getPlayableNotes", this._playableNotes);
       /*
               if(this._showHelpLines) {
                 for(let i=0;i<this._playableNotes.length;i++) {
                   this._playableNotes.getNote(i).showHelpLines = true;
                 }
              }*/
       return this._playableNotes;
    }



    noteInPlayableNotes(arg) {
       let row = (arg.row != undefined) ? arg.row : -1;
       let column = (arg.column != undefined) ? arg.column : -1;
       let finger = (arg.finger != undefined) ? arg.finger-0 : -1;
       let lage = (row == 0) ? -1 : this._playingLage;
       let showName = (arg.showName != undefined) ? arg.showName : false;
       let showTipp = (arg.showTipp != undefined) ? arg.showTipp : false;


       if(this.settings.gameMode == GAME_MODE.multipleFingerBoard) {
          // checks if the note clicked is clickable
          if(this._multipleNotes.filter((note) => {return (note.row == row && note.column == column)}).length == 0 && column != -1 && row != -1) { //column and row != -1 to allow a click on the FingerNumber
             return false;
          }
       }



       let obj = {};

       let notes = this._playableNotes.getAllNotes();
       let check = false;
       obj = new PlayableNote({
          row: row,
          column: column,
          finger: finger,
          lage: lage,
          showName: showName,
          playableNotes: this._playableNotes,
          showTipp: showTipp
       });
       for (let i = 0, max = notes.length; i < max; i++) {
          if (notes[i].row == row && notes[i].column == column) {

             obj.playable = true;
             check = true
             break;
          }
       }

       if (!check) {
          obj.playable = false;
          if(this.settings.fingerMode != FINGER_MODE.clickOnFinger) {
            return false;
         }
       }
       return obj;
    }

_playingLageUp() {
   const index = this.settings.lagen.indexOf(this.playingLage)+1;
   if(index > 0 && index < this.settings.lagen.length) {
      this.playingLage = this.settings.lagen[index];
   }
}

_playingLageDown() {
   const index = this.settings.lagen.indexOf(this.playingLage)-1;
   if(index >= 0) {
      this.playingLage =  this.settings.lagen[index];
   }
}

    set playingLage(pLage) {
       if (this.settings.lagen.includes(pLage) && this._playingLage != pLage) {
          if (pLage < VioLearn.lagen.length && pLage >= 0) {
             this._playingLage = pLage;
             //this._fingers.playingLage = this._playingLage;
             this._fire(this._playingLage, "newPlayingLage");
             this._fire(this._playingLage, "FINGERBOARD_playingLage");
             this._fire(this._playingLage, "printPlayingLage");

          }
       }

       if (this._currentNote != null && !this.settings.playingLageAuto) {
          if (this._currentNote.softEqualVar(this._playingLage, "lage")) {
             this._fire(true, "unlockClick_NoteBoard");
          } else {
             this._fire(true, "inhibitClick_NoteBoard");
          }
       }
    }
    get playingLage() {
      return this._playingLage;
   }




    _setNewCurrentNote() {
       this._fire(true, "printRemoveHighlight");
       this._fire(true, "PRINT_GAMEBOARD_showTipp_remove");
       this._currentNote = this._playableNotes.getRandomNoteClone(NOTE_TYP.fingerBoard);

       if (Array.isArray(this._currentNote.name)) {
          this._currentNote.index = getRandomInt(2);
       }

       this._currentNote._refresh(); // zwinge ihn die Lage zu refreshen
       let lage = this._currentNote.lage;
       if (Array.isArray(lage)) {
          lage = lage[getRandomInt(lage.length)].lage;
       }
       /*console.log("lage", lage);
        this._currentNote.lage = lage;
*/
       if (this.settings.playingLageAuto) {
          this.playingLage = lage;
          this._fire(true, "unlockClick_NoteBoard");
       }
       this.playingLage = this.playingLage; // refresh inhibit & unlock Note Board for when the Lage is not set auto



       this._fire(this._currentNote, "printMultipleChoiseFingerBoard");
       this._fire([this._currentNote], "PRINT_GAMEBOARD_multiple_notes");
       console.log("CURRENT NOTE", this._currentNote);
       this._fire(this._currentNote, "newCurrentNote");
    }

    _setMultiplePossibilities(note) {

      if(this.settings.gameMode != GAME_MODE.multipleFingerBoard)
         return;

       if (Array.isArray(note.row)) {
          console.log("SET XY RANDOM");
          note.setXY(note.row[getRandomInt(note.row.length)]);
          this.playingLage = note.lageProposal;
       }

       if (!(note instanceof PlayableNote)) {
          note = this._currentNote;
       }



       this._currentNote = note;

       let notes = [];
       notes.push(note);

       for (let i = 1; i < this.settings.numNotesFingerBoard; i++) {
          notes.push(this._playableNotes.getRandomNoteClone(NOTE_TYP.fingerBoard));

          let index = notes.length - 1;
          for (let j = 0; j < notes.length - 1; j++) {
             if (notes[index].row == notes[j].row && notes[index].column == notes[j].column) {
                notes.splice(i, 1);
                i--;
                break;
             }
          }
       }
       console.log(notes);
       this._multipleNotes = [];
       this._multipleNotes = notes;

       if (this._isPlaying) {
          this._fire(this._multipleNotes, "printMultipleChoiseFingerBoard");
          this._fire(this._multipleNotes, "PRINT_GAMEBOARD_multiple_notes");
       }
    }

    refreshPlayableNotes() {
       this.getPlayableNotes(this.settings.lagen, this.settings.group, this.settings.tonality, this._playingLage, this.settings.basicTone);
    }


    observe(arg, action, type) {
       if (type == "FORWARD_EVENT") {
          if (action == "GET_PLAYING_LAGE") {
             arg(this._playingLage);
          } else if (action == "GET_PLAYING_LAGE_AUTO") {
             arg(this.settings.playingLageAuto);
          } else
             this._fire(arg, action);
       }

       // SETTINGS_EVENT
       if (typeof action == "string") {
          let nAction = action.split('_');
          if (nAction[0] == "SETTINGS") {
             switch (nAction[1]) {
                case "lagen":
                case "group":
                case "tonality":
                case "basicTone":
                case "tippsActive":
                case "defaultShowNames":
                case "notesTurnable":
                   this.refreshPlayableNotes();
                   break;
                case "fingerMode": // wenn der Finger Click Mode nicht aktiviert ist müssen immer die Finger angezeigt werden
                   if (this.settings.fingerMode != FINGER_MODE.click && this.settings.showFinger != true) {
                      this.settings.showFinger = true;
                   }
             }

          }
       }


       if (this.debug)
          console.log("Fingerboard::observe", arg, action);
       switch (action) {

          case "startGame":
             this.start();
             break;
          case "pauseGame":
             this.pause(!arg);
             break;
          case "stopGame":
             this.stop();
             break;
          case "clickCurrentLageUp":
          this._playingLageUp();
             break;
          case "clickCurrentLageDown":
             this._playingLageDown();
             break;
          case "newCurrentNote":
             if (this.settings.playingLageAuto)
                this.playingLage = arg.lageProposal;
             break;


             /*case "fireCurrentNoteNoteBoard":
                 this.fireCurrentNote = !arg;
                 break;*/
          case "nextNoteInFingerBoard":
             //if(this._fireCurrentNote)
             this._setNewCurrentNote();
             this._playableNotes._showTippOnNoteBoard = true;
             this._playableNotes._showTippOnFingerBoard = false;
             break;
          case "SETTINGS_numNotesFingerBoard":
          case "setNewMultipeFingerBoard":
             this._setMultiplePossibilities(arg);
             break;


          case "removeMultipleFingerBoard":
             this._multipleNotes = [];
             this._fire(true, "printRemoveHighlight");
             this._fire(true, "PRINT_GAMEBOARD_showTipp_remove");
             break;
          case "removeCurrentNoteFingerBoard":
             this._currentNote = null;
             this._fire(true, "printRemoveHighlight");
             this._fire(true, "PRINT_GAMEBOARD_showTipp_remove");
             this._playableNotes._showTippOnNoteBoard = false;
             this._playableNotes._showTippOnFingerBoard = true;
             break;
       }
       //console.log("FINGERBOARD isPlaying", this._isPlaying);
       if (this._isPlaying) {
          let obj = null
          switch (action) {
             case "clickFingerBoardButton":
                obj = this.noteInPlayableNotes(arg);
                console.log("FINGERBOARD::observe CLICK", arg, obj);
                if (obj != false)
                   this._fire(obj, "clickNote");
                break;
             case "dropFinger":
                obj = this.noteInPlayableNotes(arg);
                console.log("DROP", arg, obj);
                if (obj != false)
                  console.log("FIRE FINGERBOARD dropNote");
                   this._fire(obj, "dropNote");
                break;
             case "clickFingerNumber":
                obj = this.noteInPlayableNotes({
                   finger: arg
                });
                console.log("FINGERBOARD::CLICK_FINGER_NUM", arg, obj);
                if (obj != false) {
                   console.log("FIRE");
                   this._fire(obj, "clickFingerNote");
                }
                break;
          }
       }

    }
 }
