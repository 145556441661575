// Javascript Document
'use strict';

/*
Subscribe to
- NoteBoard
- FingerBoard
*/

class GameLogik extends Publisher {
  constructor(arg) {
    super();
    this.settings = arg;

    this.debug = false;
    this._isPlaying = false;
    this._addToStats = true;

    this._winCondition = [];
    this._looseCondition = [];
    this._levelCondition = this.settings.levelCondition;
    /*this._bronzeCondition = this.settings.bronzeCondition;
    this._silverCondition = this.settings.silverCondition;
    this._goldCondition = this.settings.goldCondition;
    this._endCondition = null;*/


    this.compileWinLooseCondition();

    this._currentNote = {};
    this._fingerMode = this.settings.fingerMode;
    this._stat = null;

    this._durationTimer = null;
    this._timeUntilNextMoveTimer = null;
    this._secTimer = null;
    this._wrongOnesInARow = 0;

    this.resetStats();

  }

  init(arg) {
 }

  resetStats() {
    this._stat = {
      rightOnes: 0,
      wrongOnes: 0,
      rightOnesInARow: 0,
      wrongOnesInARow: 0,
      duration: false,
      timeUntilNextMove: false,
      timedout: false,
      win: false
    }
    this._fire(this._stat, "newStat");
  }

  checkNote(note) {
    if (this.debug)
      console.log("GAMELOGIK::noteClicked");
    var cNote = this._currentNote;

    console.log("GAMELOGIK::checkNote", cNote, note, "noteIntegrity " + note.integrity);
    if (cNote.isEqual(note)) {
      let addToStats = false;

      if (cNote.showTipp == true) {
        cNote.showTipp = false;
        cNote.showName = true;
      } else {
        if (!cNote.showName && this._wrongOnesInARow == 0)
          addToStats = true;
        cNote.showName = false;
      }
      this._wrongOnesInARow = 0;

      console.log("GAMELOGIK::checkNote CORRECT", addToStats);
      this._rightOne(cNote.showTipp, addToStats);

    } else {
      console.log("GAMELOGIK::checkNote NOT EQUAL");
      this._wrongOnesInARow++;
      this._wrongOne();

      if (note.showName == false)
        note.showName = true;

        console.log("GAMELOGIK::wrongOnesInARow", this._wrongOnesInARow >= this.settings.showTippAfterNumWrongOnes, this._wrongOnesInARow,  this.settings.showTippAfterNumWrongOnes)
      if (this._wrongOnesInARow >= this.settings.showTippAfterNumWrongOnes) {
         console.log("MODULSHOWTIPP TRY showTipp = true");
        note.showName = true;
        cNote.showTipp = true;
        cNote.showName = true;
      }
    }
  }

  _rightOne(showTipp = false, addToStats = false) {
    this._setTimeUntilNextMoveTimer();

    if ( /*this._addToStats -> Alte Methode*/ addToStats == true) {
      if (this._stat.wrongOnesInARow > 0) {
        this._stat.wrongOnesInARow = 0;
        this._stat.rightOnesInARow = 1;
      } else {
        this._stat.rightOnesInARow++;
      }
      this._stat.rightOnes++;

      if (showTipp) {
        this._stat.rightOnesInARow--;
        this._stat.rightOnes--;
      }
    }

    this.checkEndCondition();
    this._fireNext();
    this._fire(this._stat, "newStat");
  }

  _fireNext() {}

  _wrongOne() {
    if (this._stat.rightOnesInARow > 0) {
      this._stat.wrongOnesInARow = 1;
      this._stat.rightOnesInARow = 0;
    } else {
      this._stat.wrongOnesInARow++;
    }

    this._stat.wrongOnes++;
    this._fire(this._stat, "newStat");
    return this.checkEndCondition();
  }

  fireEndOfGame(win) {
    this._clearAllTimers();
    this._stat.win = win;
    this._fire(this._stat, "fireGameEnd");
  }

  _checkCondition(condition, fireEndOfGame) {

    if ( // RIGHT ONES
      (condition.type == END_CONDITON_TYPE.rightOnes && condition.number == this._stat.rightOnes) || //WRONG ONES
      (condition.type == END_CONDITON_TYPE.wrongOnes && this._stat.wrongOnes == condition.number) || // RIGHT ONES IN A ROW
      (condition.type == END_CONDITON_TYPE.rightOnesInARow && this._stat.rightOnesInARow == condition.number) || // WRONG ONES IN A ROW
      (condition.type == END_CONDITON_TYPE.rightOnesInARow && this._stat.rightOnesInARow == condition.number)
    ) {
      if (fireEndOfGame)
        this.fireEndOfGame(condition.win);
      return true;
    }
    return false;
  }

  checkEndCondition() {
     console.log("GAMELOGIK::checkEndCondition", this._winCondition, this.looseCondition);
    // Check WIN
    for (let i = 0; i < this._winCondition.length; i++) {
      if (this._checkCondition(this._winCondition[i], true, true))
        return true;
    }

    // Check LOOSE
    for (let i = 0; i < this._looseCondition.length; i++) {
      if (this._checkCondition(this._looseCondition[i], true, true))
        return true;
    }
    return false;
  }

  compileWinLooseCondition() {

     if(this._levelCondition == undefined && this._levelCondition.length == 0)
      return false;
     console.log("GAMELOGIK::compileWinLooseCondition", this._levelCondition);
     this._winCondition = this._levelCondition.filter(cond => cond.win == true);
     this._looseCondition = this._levelCondition.filter(cond => cond.win == false);

     /*
    var filter = function(newCondition, win) {
      var winCond = newCondition[0].filter(cond => cond.win == win);
      if (winCond.length > 0) {
        return winCond;
      } else if (newCondition.length > 1) {
        return filter(newCondition.slice(1), win);
      } else {
        return [];
      }
    }
    console.log("GAMELOGIK::winCondition", filter(this._levelCondition));
    this._winCondition = this._levelCondition.filter(cond => cond.win == true);
    this._looseCondition = this._levelCondition.filter(cond => cond.win == false);
    /*this._winCondition = filter([this._goldCondition, this._silverCondition, this._bronzeCondition, this._levelCondition], true);

    this._looseCondition = filter([this._levelCondition, this._bronzeCondition, this._silverCondition, this._goldCondition], false)
    //console.log("WIN", this._winCondition);
    //console.log("LOOSE", this._looseCondition);*/
  }


  /*
      checkEndCondition() {
          if (this.debug)
              console.log("GameRightOnes::checkEndCondition()")
          var endCondition = this._endCondition;
          //if (this.debug)
              console.log(endCondition);
          for (var i = 0; i < endCondition.length; i++) {
              // DURATION -> not needed because starts

              // RIGHT ONES
              if(endCondition[i].type == END_CONDITON_TYPE.rightOnes && endCondition[i].number == this._stat.rightOnes) {
                  this.fireEndOfGame(endCondition[i].win);
                  return true;
              }
              // WRONG ONES
              else if (endCondition[i].type == END_CONDITON_TYPE.wrongOnes && this._stat.wrongOnes == endCondition[i].number) {
                  this.fireEndOfGame(endCondition[i].win);
                  return true;
              }
              // Right Ones In A Row
              else if (endCondition[i].type == END_CONDITON_TYPE.rightOnesInARow && this._stat.rightOnesInARow == endCondition[i].number) {
                  this.fireEndOfGame(endCondition[i].win);
                  return true;
              }
              // Wrong Ones In A Row
              else if (endCondition[i].type == END_CONDITON_TYPE.wrongOnesInARow && this._stat.wrongOnesInARow == endCondition[i].number) {
                  this.fireEndOfGame(endCondition[i].win);
                  return true;
              }

          }
          return false;
      }
      */

  _clearAllTimers() {
    clearTimeout(this._durationTimer);
    clearTimeout(this._timeUntilNextMoveTimer);
    clearTimeout(this._secTimer);
    this._secTimer = null;
  }

  _setTimeUntilNextMoveTimer(time) {}



  // sets a Timer for 1 second and increments and fires the stat does not do anything with the logic - it only counts
  _setSecondsTimer() {
    clearTimeout(this._secTimer);
    this._secTimer = setTimeout(function(context = this) {
      return function() {
        if (context._stat.duration != false && context._stat.duration > 0)
          context._stat.duration--;
        else {
          context._stat.duration = false;
        }

        if (context._stat.timeUntilNextMove != false && context._stat.timeUntilNextMove > 0) {
          context._stat.timeUntilNextMove--;
        } else {
          context._stat.timeUntilNextMove = false;
        }


        if (context._stat.timeUntilNextMove != false || context._stat.duration != false) {
          context._fire(context._stat, "newStat");
          context._setSecondsTimer();
        } else {
          context._secTimer = null;
        }

      };
    }(this), 1000);
  }

  // init or restart Timers if possible
  _initTimers(duration = undefined, timeUntilNext = undefined) {
    let endCond = this._endCondition;
    let obj = this;

    for (let i in endCond) {
      if (endCond[i].type == END_CONDITON_TYPE.duration) { // Setzte Spiel Timer
        if (duration == undefined)
          duration = endCond[i].number;
        if (this._secTimer == null) {
          this._setSecondsTimer();
        }
        this._stat.duration = duration;
        this._fire(this._stat, "newStat");

        this._durationTimer = setTimeout((function(context = this) { //end of game
          return function() {
            context._stat.timedout = true;
            context._stat.duration = 0;
            context._fire(context._stat, "newStat");
            context.fireEndOfGame(endCond[i].win);
          }
        })(this), duration * 1000);


      } else if (endCond[i].type == END_CONDITON_TYPE.timeUntilNextMove) { // Setze timer bis zum nächsten Spielzug



        if (timeUntilNext == undefined)
          timeUntilNext = endCond[i].number;
        if (this._secTimer == null) {
          this._setSecondsTimer();
        }

        // set the Timer Funktion -> is called from right one function to restart the timer
        this._setTimeUntilNextMoveTimer = (function(context = this) {
          return function(time = endCond[i].number) {
            clearTimeout(context._timeUntilNextMoveTimer);

            this._stat.timeUntilNextMove = time;
            this._fire(this._stat, "newStat");

            context._timeUntilNextMoveTimer = setTimeout(function() {
              if (!context._wrongOne()) { //setze einen Falschen Spielzug
                // wenn Spiel noch nicht fertig dann setze die Zeit bis zum nächsten Spielzug neu
                context._setTimeUntilNextMoveTimer(); // setzte den Timer erneut.
                context._stat.timeUntilNextMove = time;
                if (context._secTimer == null) {
                  context._setSecondsTimer();
                }
              } else { // set the Timer Stat to 0 when the game ends
                context._stat.timeUntilNextMove = 0;
                context._fire(context._stat, "newStat");
              }

            }, timeUntilNext * 1000);
            //  console.log("!!!!!!!!!!!!!!!!!", typeof context._timeUntilNextMoveTimer);
          }
        })(this);

        this._setTimeUntilNextMoveTimer(timeUntilNext);
      }
    }

  }


  start() {
    this._isPlaying = true;
    this._fire(this._stat, "newStat");
    this.resetStats();
    this._initTimers();
  }

  pause() {

    if (this._isPlaying) {
      this._clearAllTimers();
      this._isPlaying = false;
    } else {
      this._isPlaying = true;
      //console.log(this._stat.duration, this._stat.timeUntilNextMove);
      this._initTimers(this._stat.duration, this._stat.timeUntilNextMove);
    }
  }

  stop() {
    this._clearAllTimers();
    this._isPlaying = false;
   this._wrongOnesInARow = 0;
  }

  _observePlaying(arg, action) {}
  _observeAdditional(arg, action) {}
  destroy() {}

  observe(arg, action) {
    if (this.debug)
      console.log("GAMELOGIK::observe", action, arg);
    const path = action.split("_");

    if (path[0] == "SETTINGS") {
      switch (path[1]) {
        case "endCondition":
          this._endCondition = arg;
          break;
        case "levelCondition":
          this._levelCondition = arg;
          this.compileWinLooseCondition();
          break;
        /*case "bronzeCondition":
          this._bronzeCondition = arg;
          break;
        case "goldCondition":
          this._goldCondition = arg;
          break;
        case "silverCondition":
          this._silverCondition = arg;
          break;*/
        case "fingerMode":
          this._fingerMode = arg;
      }
    }

    switch (action) {
      case "newCurrentNote":
        this._currentNote = arg;
        break;
      case "startGame":
        this.start();
        break;
      case "pauseGame":
        this.pause();
        break;
      case "stopGame":
        this.stop();
        break;
      case "newTypShowMatrix": // kann entfernt werden ????
        if (arg == TYP_SHOW_MATRIX.point)
          this._addToStats = true;
        else
          this._addToStats = false;
        break;
      case "clickShowTipp":
        this._currentNote.showName = true;
        this._currentNote.showTipp = true;
        break;
    }

    this._observeAdditional(arg, action);

    if (this._isPlaying) {
      this._observePlaying(arg, action);
    }

  }
}
