'use strict';
/*

Problem: Filter Multiple Choise - nicht DIs und Es note gleichzeitig anzeigen


*/

class Note {

   constructor(arg) {

      arg = (arg == undefined) ? {} : arg;

         this._playableNotes = (arg.playableNotes != undefined) ? arg.playableNotes : undefined;

         let column = (arg.column != undefined) ? arg.column : -1;
         let row = (arg.row != undefined) ? arg.row : -1;

         if (row != -1 && column != -1)
            this._xy = [{
               column: column,
               row: row
            }];
         else
            this._xy = -1;

         this._name = (arg.name != undefined) ? arg.name : "";
         this._index = (arg.index != undefined) ? arg.index : -1;

         this._finger = (arg.finger != undefined) ? arg.finger : -1;
         this._lage = (arg.lage != undefined) ? arg.lage : -1;

         this._refresh();
   }

   static get STATUS() {
      return {
         NOTSET: 0,
         SET: 1,
         ARRAY: 2
      };
   };

   _checkVarStatus(obj, type = "normal") {
      let status = Note.STATUS;

      if (type != "xy") {
         if (obj === -1 || obj === "" || (Array.isArray(obj) && obj.length == 0))
            return status.NOTSET;
         else if (Array.isArray(obj))
            return status.ARRAY;
         else
            return status.SET;
      } else if (type == "xy") {
         if (obj == -1 || obj[0].row == -1 || obj[0].column == -1)
            return status.NOTSET;
         else if (obj.length > 1)
            return status.ARRAY;
         else
            return status.SET;
      }
   }



   //refreshes XY, Name, and index
   _refresh() {
      let status = Note.STATUS;

      if (this._checkVarStatus(this._name) != status.SET)
         this._refreshName();
      if (this._checkVarStatus(this._index) != status.SET)
         this._refreshIndex();
      if (this._checkVarStatus(this._xy, "xy") != status.SET)
         this._refreshXY();

      if (this._checkVarStatus(this._finger) != status.SET)
         this._refreshFingerOrLage("_finger");
      if (this._checkVarStatus(this._lage) != status.SET)
         this._refreshFingerOrLage("_lage");
   }

   _refreshIndex() {
      let status = Note.STATUS;

      let findIndex = function(arr, context) {
         for (let i = 0; i < arr.length; i++) {
            if (arr[i] == context._name) {
               context._index = i;
               break;
            }
         }
      }

      if (this._checkVarStatus(this._index) == status.NOTSET &&
         this._checkVarStatus(this._name) == status.SET) {
         if (this._checkVarStatus(this._xy, "xy") == status.SET) {
            findIndex(VioLearn.matrix[this._xy[0].row][this._xy[0].column], this);
         } else if (this._checkVarStatus(this._xy, "xy") == status.ARRAY) {
            for (let i = 0; i < this._xy.length; i++) {
               findIndex(VioLearn.matrix[this._xy[i].row][this._xy[i].column], this);
               if (this._checkVarStatus(this._finger) == status.SET)
                  break;
            }
         } else if (this._playableNotes != undefined) {
            let pos = this._playableNotes.getAllPosToName(this._name);
            let xy = pos[0];
            findIndex(VioLearn.matrix[xy.row][xy.column], this);
         }

      }
      return this._index;
   }

   _refreshName() {
      //wenn der derzeitige name kein Array ist, und text enthaltet und nicht undefiniert ist, führe kein Refresh durch
      if (this._name != "" && this._name != undefined && !Array.isArray(this._name))
         return this._name;

      // wenn name ein array ist, schaue ob index definiert ist, wenn ja setze den printGameFieldCenter
      if (Array.isArray(this._name) && this._index != -1) {
         this._name = this._name[this._index];
         return this._name;
      }

      // try to get name from xy + index
      if (this._checkVarStatus(this._xy, "xy") == Note.STATUS.SET) {
         //if (this._xy.length == 1 && this._xy.row != -1 && this._xy.column != -1) {
         if (this._index != -1) {
            this._name = VioLearn.matrix[this._xy[0].row][this._xy[0].column][this._index];
         } else if (this._name != VioLearn.matrix[this._xy[0].row][this._xy[0].column]) {
            this._name = VioLearn.matrix[this._xy[0].row][this._xy[0].column];
            if (this._name.length == 1) {
               this._index = 0;
               this._name = this._name[0];
            }
         }

      } else {} // es sind zuviel verschiedene Noten möglich - daher wird der name nicht gesetzt

      return "";
   }

   _refreshFingerOrLage(_finger) { // für die Lesbarkeit wird als Variable Key Name _finger verwendet
      let _lage = (_finger == "_finger") ? "_lage" : "_finger";
      let lage = (_finger == "_finger") ? "lage" : "finger";
      let finger = (_finger == "_finger") ? "finger" : "lage";

      let status = Note.STATUS;

      // wenn nicht gesetzt refresh den Array
      if (this._checkVarStatus(this[_finger]) != status.SET) {
         this[_finger] = (this._playableNotes != undefined) ? this._playableNotes.getPossibleLageAndFinger(this) : -1;

         // setzte den Finger nur wenn die Lage dazupasst.
         if (this[_finger].length == 1 && this[_finger][0][lage] == this[_lage])
            this[_finger] = this[_finger][0].finger;
      }

      //wenn die Lage gesetzt ist schau ob im Finger array der richtige Finger ist und ordne ihne wenn möglich zu
      if (this._checkVarStatus(this[_finger]) == status.ARRAY &&
         this._checkVarStatus(this[_lage]) == status.SET) {
         for (let i = 0; i < this[_finger].length; i++) {
            if (this[_finger][i][lage] == this[_lage] && this._checkNumKeyInArray(this[_finger], lage, this[_lage]) == 1) {
               this[_finger] = this[_finger][i][finger];
               break;
            }
         }
      }
      return this[_finger];
   }

   _checkNumKeyInArray(arr, key, elem) {
      let num = 0;
      for (let i = 0; i < arr.length; i++) {
         if (arr[i][key] == elem)
            num++;
      }
      return num;
   }

   /*
       _refreshFinger() {
           let status = Note.STATUS;
           if (this._checkVarStatus(this._finger) == status.NOTSET) {
               this._finger = NOTES.getPossibleLageAndFinger(this);
               if (this._finger.length == 1 && this._finger[0].lage == this._lage)
                   this._finger = this._finger[0].finger;
           } else if(this._checkVarStatus(this._finger) == status.ARRAY) { // the array could change because of new information
               var tmp = NOTES.getPossibleLageAndFinger(this);
               if(tmp != this._finger) {
                   this._finger = tmp;
               }
           }
           if (this._checkVarStatus(this._finger) == status.ARRAY &&
               this._checkVarStatus(this._lage) == status.SET) {
               for (let i = 0; i < this._finger.length; i++) {
                   if (this._finger[i].lage == this._lage) {
                       this._finger = this._finger[i].finger;
                       break;
                   }
               }
           }
           return this._finger;
       }

       _refreshLage() {
           if (this._checkVarStatus(this._lage) == status.ARRAY) {
               this._lage = NOTES.getPossibleLageAndFinger(this);
               if (this._lage.length == 1 && this._lage[0].finger == this._finger)
                   this._lage = this._lage[0].lage;
           } else if(this._checkVarStatus(this._lage) == status.ARRAY) { // the array could change because of new information
               var tmp = NOTES.getPossibleLageAndFinger(this);
               if(tmp != this._lage) {
                   this._lage = tmp;
               }
           }

           if (this._checkVarStatus(this._lage) == status.ARRAY &&
               this._checkVarStatus(this._finger) == status.SET) {
               for (let i = 0; i < this._lage.length; i++) {
                   if (this._lage[i].finger == this._finger) {
                       this._lage = this._lage[i].lage;
                       break;
                   }
               }
           }
           return this._lage;
       }*/

   _checkIfPosInLage(pos) {
      if (this._checkVarStatus(this.lage) == Note.STATUS.SET) {
         let newPos = [];
         for (let i = 0; i < pos.length; i++) {
            let index = pos[i].row - VioLearn.lagen[this.lage].shift - 1;
            let matrix = VioLearn.lagen[this.lage].pos;
            if (index >= 0 && index < matrix.length) {
               if (this._checkVarStatus(this.index) == Note.STATUS.SET) {
                  if (matrix[index][pos[i].column][this.index] != -1) {
                     newPos.push(pos[i]);
                  }
               } else if ((matrix[index][pos[i].column][0] != -1 || matrix[index][pos[i].column][1] != -1)) {
                  newPos.push(pos[i]);
               }

            }

         }
         return newPos;
      }
      return pos;
   }

      _checkIfPosInLageAndFinger(pos) {

         if (this._checkVarStatus(this._lage) == Note.STATUS.SET && this._checkVarStatus(this.finger) == Note.STATUS.SET) {

            let newPos = [];
            for (let i = 0; i < pos.length; i++) {
               let index = pos[i].row - VioLearn.lagen[this.lage].shift - 1;
               let matrix = VioLearn.lagen[this.lage].pos;
               if (index >= 0 && index < matrix.length) {
                  if (this._checkVarStatus(this._index) == Note.STATUS.SET) {
                     if (matrix[index][pos[i].column][this.index] == this.finger) {
                        newPos.push(pos[i]);
                     }
                  } else if (matrix[index][pos[i].column][this.index].includes(this.finger)) {
                     newPos.push(pos[i]);

                  }

               }

            }
            return newPos;
         }
         return pos;
      }


      _refreshXY() {
         if (this._checkVarStatus(this._xy, "xy") != Note.STATUS.SET && (this._playableNotes != undefined)) {
            let newPos = this._playableNotes.getPosToName(this._name);

            newPos = this._checkIfPosInLage(newPos);

            newPos = this._checkIfPosInLageAndFinger(newPos);


            if (this._xy != newPos && newPos.length >= 1) {
               this._xy = newPos;
            }

         }
         return this._xy;
      }

      set name(name) {
         if (this.name != name) {
            this._name = name;
            this._refresh();
         }
      }

      set index(index) {
         if (this._index != index) {
            this._index = index;
            this._refresh();
         }
      }

      setXY(xy) {
         if (this._xy != xy) {
            if (arguments.length > 1) {
               xy = {
                  column: arguments[0],
                  row: arguments[1]
               };
            }

            if (Array.isArray(xy))
               this._xy = xy;
            else
               this._xy = [{
                  column: xy.column,
                  row: xy.row
               }];

            this._refresh();
            // get Name to Position
         }
      }


      set lage(lage) {
         if (this._lage != lage) {
            this._lage = lage;
            this._refresh();
         }
      }

      set finger(finger) {
         if (this._finger != finger) {
            this._finger = finger;
            this._refresh();
         }
      }

      get xy() {
         return this._xy;
      }

      get column() {
         if (this._xy.length == 1)
            return this._xy[0].column;
         else {
            //console.log("ERROR Note::get column ", this._xy);
            return this._xy;
         }
      }

      get row() {
         if (this._xy.length == 1)
            return this._xy[0].row;
         else {
            //console.log("ERROR Note::get column ", this._xy);
            return this._xy;
         }
      }

      get name() {
         return this._name
      }

      get index() {
         return this._index;
      }

      get lage() {
         return this._lage
      }

      // Problem bei Grundton ???
      get lageProposal() {
         if (this._checkVarStatus(this._lage) == Note.STATUS.SET) {
            return this._lage;
         } else if (this._checkVarStatus(this._lage) == Note.STATUS.ARRAY) {
            let rand = getRandomInt(this._lage.length);
            let ret = this._lage[rand].lage;
            return ret;
         } else if (this._checkVarStatus(this._lage) == Note.STATUS.NOTSET) {
            var possibilities = (this._playableNotes != undefined) ? this._playableNotes.getPossibleLageAndFinger(this) : -1;
            if (possibilities == -1  || possibilities.length == 0) // only happens when index and row is not set
               return -1;
            else {
               return possibilities[getRandomInt(possibilities.length)].lage;
            }
         }
      }

      get finger() {
         return this._finger;
      }

      // checks if Finger and Lage and XY pos are all pointing to the same note
      get integrity() {
         let status = Note.STATUS;

         var check1 = false;
         var check2 = false;
         var context = this;

         let init = function() {
            check1 = (context._checkVarStatus(context._lage) != status.SET) ? true : false;
            check2 = (context._checkVarStatus(context._finger) != status.SET) ? true : false;
         }
         let possibilities = (this._playableNotes != undefined) ? this._playableNotes.getPossibleLageAndFinger(this) : -1; // gets all possible combinations for the set XY Position
         //console.log("possibilities", possibilities);

         // wenn zuwenig Daten vorhanden sind und keine Möglichkeiten generiert werden können gib return true zurück;
         if (possibilities == -1)
            return true;

         for (let i in possibilities) {

            if (possibilities[i].lage == -1 && possibilities[i].finger == -1)
               return true;

            init(); // bei -1 wird die check variable automatisch auf true gesetzt.

            if (possibilities[i].lage == this._lage) {
               check1 = true;
            }
            if (possibilities[i].finger == this._finger) {
               check2 = true;
            }

            if (check1 && check2)
               return true;
         }

         return false;
      }

      hasFinger() {
         return (this._checkVarStatus(this._finger) == Note.STATUS.SET) ? true : false;
      }
      hasLage() {
         return (this._checkVarStatus(this._lage) == Note.STATUS.SET) ? true : false;
      }

      resetFingerAndLage() {
         this._finger = -1;
         this._lage = -1;
      }

      _checkArrayEqual(obj1, obj2) {
         for (let i = 0; i < obj1.length; i++) {
            for (let j = 0; j < obj2.length; j++) {
               let success = true;
               for (let key in obj1[i]) {
                  if (obj1[i][key] != obj2[j][key])
                     success = false;
               }
               if (success)
                  return true;
            }
         }
      }

      _checkElementInArray(arr, obj, key) {
         let eq = false;
         for (let i = 0; i < arr.length; i++) {
            if (arr[i] == obj) {
               eq = true;
            } else if (arr[i][key] == obj)
               eq = true;

         }
         if (eq)
            return true;
         return false;
      }

      softEqualVar(obj2, key) {
         return this._checkEqual(this[key], obj2, key);
      }

      _checkEqual(obj1, obj2, key = "", hardEqual = false, debug = false) {
         let status = Note.STATUS;
         if (debug)
            console.log("check", obj1, obj2, key);


         if (hardEqual) {
            if (Array.isArray(obj1) && Array.isArray(obj2)) {
               return this._checkArrayEqual(obj1, obj2);
            }
            return (obj1 === obj2);
         }

         if ((this._checkVarStatus(obj1) == status.NOTSET || this._checkVarStatus(obj2) == status.NOTSET)) {
            return true;
         }

         if (key == "xy") {
            return this._checkArrayEqual(obj1, obj2); // unabhängig davon ob es gesetzt ist oder nicht ist der Typ IMMER ein Array mit column und row und kann deshalb allein behandelt werden.
         }

         if (this._checkVarStatus(obj1, key) == status.SET && this._checkVarStatus(obj2, key) == status.SET) {
            if (debug)
               console.log("IF 1");
            return (obj1 == obj2);
         } else if (this._checkVarStatus(obj1, key) == status.ARRAY && this._checkVarStatus(obj2, key) == status.SET) {
            if (debug)
               console.log("IF 2");
            return this._checkElementInArray(obj1, obj2, key);
         } else if (this._checkVarStatus(obj1, key) == status.SET && this._checkVarStatus(obj2, key) == status.ARRAY) {
            if (debug)
               console.log("IF 3");
            return this._checkElementInArray(obj2, obj1, key);
         }
         if (debug)
            console.log("END");
         return true;
         /*
         //obj1&2 - beide sind entweder vom Typ array oder object
         if ((!Array.isArray(obj1) && !Array.isArray(obj2)) || (Array.isArray(obj1) && Array.isArray(obj2))) {

             //vergleiche XY Koordinaten wenn mehrere Einträge exestieren.
             if (Array.isArray(obj1) && obj1[0].row != undefined) {
                 console.log("checkXY");
                 for (let i = 0; i < obj1.length; i++) {
                     for (let j = 0; j < obj2.length; j++) {
                         //console.log("check", JSON.stringify(obj1[i]), JSON.stringify(obj2[j]));
                         if (obj1[i].row == obj2[j].row && obj1[i].column == obj2[j].column) {
                             return true;
                         }
                     }
                 }
                 return false;
             }

             if (JSON.stringify(obj1) != JSON.stringify(obj2))
                 return false;

         } else if (Array.isArray(obj1) && !Array.isArray(obj2)) {
             if (!check(obj1, obj2))
                 return false;
         } else if (!Array.isArray(obj1) && Array.isArray(obj2)) {
             if (!check(obj2, obj1))
                 return false;
         }
         //console.log("equal");
         return true;
         */
      }

      /*
      hardEqual true -> when finger or something else == -1 then it is true
      */
      isEqual(nObj, hardEqual = false, debug = false) {
         if (debug)
            console.log("checkIfEqual");

         let equal = true;
         let keys = ["name", "finger", "lage", "xy"];

         //extra checkup für ColumnAndRow

         for (let i = 0; i < keys.length; i++) {
            equal = this._checkEqual(this[keys[i]], nObj[keys[i]], keys[i], hardEqual, debug);
            if (debug)
               console.log("checkEqual " + keys[i], this[keys[i]], nObj[keys[i]], equal);

            if (!equal)
               return false;
         }

         if (this.integrity != nObj.integrity) {
            return false;
         }

         if (debug)
            console.log("ALL EQUAL");
         return true;
      }

      clone() {
         let n = new Note();
         n._name = this._name;
         n._index = this._index;
         n._xy = this._xy;
         n._lage = this._lage;
         n._finger = this._finger;
         n._playableNotes = this._playableNotes;
         return n;
      }
   }
