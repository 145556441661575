// Javascript Document
'use strict';

class View_GameBoard {
   constructor() {

      $(document).ready(function () {
         $(".finger0").draggable({
            revert: true
         });
         $(".finger1").draggable({
            revert: true
         });
         $(".finger2").draggable({
            revert: true
         });
         $(".finger3").draggable({
            revert: true
         });

         $(".gameField").draggable({cancel: '.fingers'});

      });

   }

   /*

   Input: Object of an array of notes - arguments: column, row, noteName (noteName could be an array)

   colors the Buttons for the notes in the appropriate color
   */
   colorNotes(notes, color = '#ff0000') {
      if (notes._notes != undefined) {
         notes = notes._notes;
      }

      if (this.debug) {
         console.log("+++++++++++++++++++++++++++++++++++++++++++++++++++");
         console.log("View::colorNotes", notes, color);
      }
      let jFingerButton = null;
      this._removeTurnMatrix();

      //$(".fingerMatrix .highlight").removeClass("highlight");
      $(".fingerMatrix .playableNote").removeClass("playableNote");
      $('.fingerMatrix label').filter(function() {
         return $(this).css('background-color') != 'transparent';
      }).css("background-color", "");

      let turnMatrixActive = false;
      for (let i = 0, max = notes.length; i < max; i++) {
         jFingerButton = $(".fingerBoard .trDiv:eq(" + notes[i].row + ") .fingerBoardButton:eq(" + notes[i].column + ")");

         //jFingerButton.css("background-color", color);
         jFingerButton.addClass("playableNote");

         if (notes[i].showName != false && notes[i].showName != undefined) {
            this.printTurnOverNote(notes[i]);
            turnMatrixActive = true;
         }
         //alert("FingerButton" + notes[i].row+" "+notes[i].column );
      }
      if (turnMatrixActive) {
         this._showTurnMatrix();
      }

      if (this.debug) {
         console.log("END View::colorNotes");
         console.log("+++++++++++++++++++++++++++++++++++++++++++++++++++");
      }
   }

   printRotation(arg) {
      $(".gameField").css("transform", "rotate(" + arg + "deg)");
   }
   _checkTurnMatrixIfAllFalse() {
      return ($(".fingerMatrix .showName").length > 0) ? false : true;
   }

   _removeTurnMatrix() {
      if ($(".fingerMatrix .fingerButtonBIG").length > 0) {
         $(".fingerMatrix .showName").html("").removeClass("showName");
         $(".fingerButtonBIG").removeClass("fingerButtonBIG");
         $(".currentLageBorderBIG").removeClass("currentLageBorderBIG");
         $(".fingersBIG").removeClass("fingersBIG");
         $(".fingerMatrix").trigger("classChange");
      } else {
         this._showTurnMatrix();
      }
   }

   currentLageBorderSIZE(big) {
      if(big) {
         $(".currentLageBorder").addClass("currentLageBorderBIG");
         $(".fingers").addClass("fingersBIG");
      } else {
         $(".currentLageBorderBIG").removeClass("currentLageBorderBIG");
         $(".fingersBIG").removeClass("fingersBIG");
      }
   }

   _showTurnMatrix() {
      if ($(".fingerMatrix .fingerButtonBIG").length == 0) {
         $(".fingerMatrix .fingerBoardButton").not($(".fingerMatrix div:eq(0) .fingerBoardButton")).addClass("fingerButtonBIG");
         if($(".fingerMatrix .trDiv label").length > 0) {
            $(".currentLageBorder").addClass("currentLageBorderBIG");
            $(".fingersBIG").addClass("fingersBIG");
         } else {
            $(".currentLageBorderBIG").removeClass("currentLageBorderBIG");
            $(".fingersBIG").removeClass("fingersBIG");
         }

         $(".currentLageBorder .fingers").addClass("fingersBIG");
         $(".fingerMatrix").trigger("classChange");
      } else {
         this._removeTurnMatrix();
      }
   }



   printTurnOverNote(note) {
      /*
      let toString = function(arg) {
         if (Array.isArray(arg)) {
            let str = "";
            for (let i = 0; i < arg.length; i++) {
               if (i > 0)
                  str += " | ";
               str += arg[i];
            }
            return str;
         } else {
            return arg;
         }
      }

      if (note.showName == false) { // hide the note
         $(".fingerMatrix div:eq(" + note.row + ") label:eq(" + note.column + ")").html("").removeClass("showName");
         if (this._checkTurnMatrixIfAllFalse(false))
            this._removeTurnMatrix();
      } else { // showtheNote
         $(".fingerMatrix div:eq(" + note.row + ") label:eq(" + note.column + ")").html(toString(note.name)).addClass("showName");
         this._showTurnMatrix();
      }
      */
   }

   printHighlightLage(arg) {
      if(arg.up == true) {
         $(".currentLageBorder .setCurrentLage:eq(1)").addClass("highlight");
      } else {
         $(".currentLageBorder .setCurrentLage:eq(0)").addClass("highlight");
      }
      //$(".currentLageBorder .setCurrentLage").addClass("highlight");
   }



   printNoteHighlightFingerBoard(arg) {
      //$(".fingerMatrix .highlight").removeClass("highlight");
      if (arg instanceof Note) {
         $(".fingerMatrix div:eq(" + arg.row + ") label:eq(" + arg.column + ")").addClass("highlight");
      } else if (arg instanceof Array) {
         for (let i = 0; i < arg.length; i++) {
            $(".fingerMatrix div:eq(" + arg[i].row + ") label:eq(" + arg[i].column + ")").addClass("highlight");
         }
      }
   }

   printMultipleChoiseFingerBoard(arg) {
      $(".fingerMatrix .highlightMultiple").removeClass("highlightMultiple");
      if (arg instanceof Note) {
         $(".fingerMatrix div:eq(" + arg.row + ") label:eq(" + arg.column + ")").addClass("highlightMultiple");
      } else if (arg instanceof Array) {
         for (let i = 0; i < arg.length; i++) {
            $(".fingerMatrix div:eq(" + arg[i].row + ") label:eq(" + arg[i].column + ")").addClass("highlightMultiple");
         }
      }
   }

   _removeMultipleChoise() {
      $(".fingerMatrix .highlightMultiple").removeClass("highlightMultiple");
   }

   _removeTipp() {
      $(".gameBoard .highlight").removeClass("highlight");
   }

   printRemoveHighlight(arg) {

      if (arg.lage == true) {
         $(".gameBoard .currentLageBorder .highlight").removeClass("highlight");
         return true;
      }

      switch(arg) {
         case "whole":
            this._removeMultipleChoise();
         default:
            this._removeTipp();
      }

   }

   showTipp(arg) {
      if (arg)
         $(".showTipp").show();
      else
         $(".showTipp").hide();
   }

   cssDur(arg) {
      if (arg == false) {
         $(".fingerMatrix .cDur").removeClass("cDur");
      } else if (Array.isArray(arg)) {
         for (let i = 0; i < arg.length; i++) {
            for (let j = 0; j < arg[i].length; j++) {
               if (arg[i][j].includes(true)) {
                  $(".fingerMatrix div:eq(" + i + ") label:eq(" + j + ")").addClass("cDur");
               }
            }
         }

      }
   }

   colorMarkup(arg) {
      $(".fingerMatrix label").removeAttr("style");

      if(Array.isArray(arg)) {
         for(let i=0;i<arg.length;i++) {
            for(let j=0;j<arg[i].length;j++) {
               if(arg[i][j].color) {
                  $(".fingerMatrix div:eq(" + i + ") label:eq(" + j + ")").css("background-color", arg[i][j].color);
               }
            }
         }
      }
   }

   scale(scale) {
      console.log("VIEWGAMEBOARD::scale", scale);
      $(".gameField").css("transform", "scale("+scale+")");
   }

   setMiddle(pos) {
      $(".gameField").css("left", pos.left);
      $(".gameField").css("top", pos.top);
   }

   observe(arg, action) {
      //if (this.debug)
         console.log("View_GAMEBOARD::observe", arg, action);

      /*
      Handels:
        printRotation
        printTurnMatrix
        printTurnOverNote
      */
      if (typeof this[action] == "function") {
         this[action](arg);
      }

      if(typeof action == "string") {
         let nAction = action.split('_');
         if(nAction[0] == "PRINT" && nAction[1] == "GAMEBOARD") {
            if (typeof this[nAction[2]] == "function") {
               this[nAction[2]](arg);
            }
         }
      }

      switch (action) {
         case 'printPlayableNotes':
            this.colorNotes(arg);
            break;
         case "clickCurrentLageUp":
         case "clickCurrentLageDown":
            this.printRemoveHighlight({
               lage: true
            });
            break;
         case "newShowTipps":
            this.showTipp(arg);
      }

   }

}
