'use strict';

let addModulTurnable = function (arg, playableNotes) {

    let privateVars = {
      isTurnable: true,
      defaultShowNameTurnable: false,
    };

    for(let key in privateVars) {
      playableNotes._vars[key] = privateVars[key];
      playableNotes["_"+key] = (arg != undefined && arg[key] != undefined) ? arg[key] : privateVars[key];
    }

    playableNotes.showName = function (showB, note) {
      if(!this._isTurnable)
        return false;

      let realNotes = this._getNoteToXY(note); // is necessary because usually it is a copy when a click is made
      if(realNotes.length == 0)
        return false;

      for(let i=0;i<realNotes.length;i++) {
        realNotes[i]._showName = showB;
        this._fire(realNotes[i], "printTurnOverNote");
        console.log("MODULTURNABLE::showName", realNotes[i]);
        this._fire(realNotes[i], "PRINT_GAMEBOARD_showName");
      }
      return true;
    }

    playableNotes.refreshShowName = function (note) {
      let realNotes = this._getNoteToXY(note);

      if(realNotes.length == 1) {
         note._showName = realNotes[0]._showName;
      }
   }

    playableNotes.resetTurnable = function () {
      for(let i=0;i<this._notes.length;i++) {
        this._notes[i]._showName = this._defaultShowNameTurnable;
      }
    }
}
