// JS Code
'use strict';

/*
Does Handle
- rotation
- showHelpLines
- colorMarkup
- cssDur
*/

class FingerBoardPresentation extends Publisher {
   constructor(arg) {
      super();

      let privateVars = {
         playingLage: 0,
         playableNotes: {},

         isPlaying: false,
      };

      this.settings = arg.settings;

      this._vars = privateVars;

      for (let key in privateVars) {
         this["_" + key] = (arg != undefined && arg[key] != undefined) ? arg[key] : privateVars[key];
      }
   }

   pause(isPlaying = !this._isPlaying) {
      this._isPlaying = isPlaying;
   }

   stop() {
      this._isPlaying = false;
      this._fireResetView();
   }

   start() {
      this._isPlaying = true;

      this._fireViewOutput();
   }

   _fireResetView() {}

   _fireViewOutput() {
      this._checkPrintSetPlayingLageVisible();

      this._fire(this.settings.showFinger, "printFingersVisible");
      this._fire(this.settings.showHelpLines, "printHelpLinesVisible");
      this._fire(this.settings.rotation, "printRotation");
      this._firePrintCssDur();
      this._fireColorMarkupPoints();

      if (this.settings.group != GROUP_MODE.none) {
         this._fire(VioLearn.groups[this.settings.group], "printFingerPos");
      } else {
         this._fire(VioLearn.groups[VioLearn.settings.defaultShowFinger[this._playingLage]], "printFingerPos");
      }

   }


   _checkPrintSetPlayingLageVisible() {
      if (this.settings.lagen.length > 1 && !this.settings.playingLageAuto) {
         this._fire(true, "printSetPlayingLageVisible");
      } else {
         this._fire(false, "printSetPlayingLageVisible");
      }
   }

   set playingLage(arg) {
      if (arg != this._playingLage) {
         this._playingLage = arg;
         if (this.settings.group == GROUP_MODE.none && this.settings.showFinger) {
            this._fire(VioLearn.groups[VioLearn.settings.defaultShowFinger[this._playingLage]], "printFingerPos");
         }
         this._fireColorMarkupPoints();
      }

   }

   set playableNotes(arg) {
      if (arg != this.playableNotes) {
         this._playableNotes = arg;
      }
   }




   _firePrintCssDur() {
      if (this.settings.showCssDur) {
         this._fire(VioLearn.tonalities[TONALITIES.cDur], "PRINT_GAMEBOARD_cssDur");
      } else {
         this._fire(false, "PRINT_GAMEBOARD_cssDur");
      }
   }

   _getKey(obj, val) {
      for(const [key, value] of Object.entries(obj)) {
         console.log(key, value);
         if(value == val)
         return key;
      }
   }

   _fireColorMarkupPoints() {
      console.log("FINGERBOARDPRESENTATION::try make color markup", this.settings.colorMarkupPoints);
      let arr = new Array(VioLearn.matrix.length).fill(0).map(() => new Array(4).fill(false));
      switch(this.settings.colorMarkupPoints) {
         case COLOR_MARKUP_FINGERBOARD.helpLines:
            if(this.settings.group != GROUP_MODE.none) {
               let group = VioLearn.groups[this.settings.group];
               for(let i=0;i<group.length;i++) {
                  let row = VioLearn.lagen[this.settings.lagen[0]].shift+group[i];

                  arr[row] = arr[row].map(elem => {return {color: COLOR_MARKUP.color[i]}});
               }
               this._fire(arr, "PRINT_GAMEBOARD_colorMarkup");
               break;
            }

            let colorRows = [];
            for(let i=0;i<this._playableNotes.length;i++) {
               let note = this._playableNotes.getNote(i);
               let color = VioLearn.helpLines.includes(note.row);
               //let color = COLOR_MARKUP.helpLines;//VioLearn.colorMarkup_getColor(note.row);
               if (color != false) {
                  colorRows.includes(note.row) ? true : colorRows.push(note.row);
                  arr[note.row][note.column] = {color: true};
               }
            }
            let colorIndex = 0;
            for(let i=0;i<colorRows.length;i++) {
               for(let j =0;j<4;j++) {
                  if(arr[colorRows[i]][j].color) {
                     if(colorRows[i] >= VioLearn.lagen[this._playingLage].shift && colorRows[i] < VioLearn.lagen[this._playingLage].shift+6) {
                        arr[colorRows[i]][j].color = COLOR_MARKUP["color"+colorIndex];
                     } else {
                        arr[colorRows[i]][j] = false;
                     }
                  }
               }
               if(colorRows[i] >= VioLearn.lagen[this._playingLage].shift && colorRows[i] < VioLearn.lagen[this._playingLage].shift+6) {
                  colorIndex++;
               }
            }
            console.log("FIRE PRINT_GAMEBOARD_colorMarkup", arr);
            this._fire(arr, "PRINT_GAMEBOARD_colorMarkup");
            break;
         case COLOR_MARKUP_FINGERBOARD.cDur:
         case COLOR_MARKUP_FINGERBOARD.bDur:
         case COLOR_MARKUP_FINGERBOARD.dDur:
         case COLOR_MARKUP_FINGERBOARD.fDur:
         case COLOR_MARKUP_FINGERBOARD.gDur:

         const tonality = TONALITIES[this._getKey(COLOR_MARKUP_FINGERBOARD, this.settings.colorMarkupPoints)];
         console.log(tonality, this._getKey(COLOR_MARKUP_FINGERBOARD, this.settings.colorMarkupPoints) );
            for(let i=0;i<this._playableNotes.absLength;i++) {
               let note = this._playableNotes.getNote(i, true);

               if(VioLearn.tonalities[tonality][note.row][note.column][note.index]) {

                  let shift = VioLearn.lagen[this._playingLage].shift-1;
                  if(note.row >= shift && note.row<shift+VioLearn.lagen[this._playingLage].pos.length) {
                     let row = note.row - shift;
                     let fingerNum = VioLearn.lagen[this._playingLage].pos[row][note.column][note.index];
                     if(fingerNum != -1)
                        arr[note.row][note.column] = {color: fingerNum};
                  }

               }
            }
            console.log("FIRE PRINT_GAMEBOARD_colorMarkup", arr);
            this._fire(arr, "PRINT_GAMEBOARD_colorMarkup");
            break;
         default:
            this._fire(false, "PRINT_GAMEBOARD_colorMarkup");
      }
   }

   observe(arg, action) {
      switch (action) {
         case "startGame":
            this.start();
            break;
         case "pauseGame":
            this.pause(!arg);
            break;
         case "stopGame":
            this.stop();
            break;

         case "newPlayingLage":
            this.playingLage = arg;
            break;
         case "newPlayableNotes":
            this.playableNotes = arg;
            break;





      }
   }
}
