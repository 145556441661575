class GameBuilder {
   constructor(settings) {
      this.settings = settings;
      this._game = new GameLogik(this.settings);

      if (this.settings.gameMode == GAME_MODE.findNote) {
         this.createGameFindNote();
      }
      else if (this.settings.gameMode == GAME_MODE.multipleNoteBoard) {
         this.createGameMultipleNoteBoard();
      }
      else if (this.settings.gameMode == GAME_MODE.multipleFingerBoard) {
         this.createGameMultipleFingerBoard();
      }
   }

   get game() {
      return this._game;
   }

   createGameFindNote() {
      console.log("GAMEBUILDER::createGameFindNote::INIT");
      this._game.init = function() {
         this._fire(true, "fireCurrentNoteNoteBoard");
      }

      this._game._fireNext = function() {
         this._fire(true, "nextNoteInNoteBoard");
      }

      this._game._observePlaying = function(arg, action) {
         switch (action) {
            case "clickNote":
               if (this._fingerMode == FINGER_MODE.click)
                  this.checkNote(arg);
               break;
            case "dropNote":
               if (this._fingerMode == FINGER_MODE.drop)
                  this.checkNote(arg);
               break;
            case "clickFingerNote":
               console.log("GAMEBUILDER::createGameFindNote::observePlaying::clickFingerNote", this._fingerMode, FINGER_MODE.clickOnFinger);
               if (this._fingerMode == FINGER_MODE.clickOnFinger)
                  this.checkNote(arg);
               break;

         }
      }
   }

   createGameMultipleNoteBoard() {
      this._game.init = function() {
         this._fire(false, "fireCurrentNoteNoteBoard");
         this._fire(true, "nextNoteInFingerBoard");
      }

      this._game._fireNext = function() {
         this._fire(true, "nextNoteInFingerBoard");
      }

      this._game._observeAdditional = function(arg, action) {
         switch (action) {
            case "newCurrentNote":
               this._fire(arg, "setNewMultipeNoteBoard");
               break;
         }
      }

      this._game._observePlaying = function(arg, action) {
         switch (action) {
            case "clickNoteNoteBoard":
               this.checkNote(arg);
               break;

         }
      }
      this._game.destroy = function() {
         this._fire(true, "removeCurrentNoteFingerBoard");
      }

   }

   createGameMultipleFingerBoard() {
      console.log("GAMEBUILDER::createGameMultipleFingerBoard");
      this._game.init = function() {
         this._fire(true, "fireCurrentNoteNoteBoard");
         //this._fire(1, "newNoteBoardNumNotes");
      }

      this._game._fireNext = function() {
         this._fire(true, "nextNoteInNoteBoard");
      }

      this._game._observeAdditional = function(arg, action) {
         switch (action) {
            case "newCurrentNote":
               this._fire(arg, "setNewMultipeFingerBoard");
               break;
         }
      }

      this._game._observePlaying = function(arg, action) {
         console.log("GAMEBUILDER::createGameMultipleFingerBoard::INIT");
            switch (action) {
               case "clickNote":
                  if (this._fingerMode == FINGER_MODE.click)
                     this.checkNote(arg);
                  break;
               case "dropNote":
                  if (this._fingerMode == FINGER_MODE.drop) {
                     this.checkNote(arg);
                  }
                  break;
               case "clickFingerNote":
               console.log("GAMEBUILDER::createGameMultipleFingerBoard::observePlaying::clickFingerNote", this._fingerMode, FINGER_MODE.clickOnFinger);
                  if (this._fingerMode == FINGER_MODE.clickOnFinger)
                     this.checkNote(arg);
                  break;

            }
      }
      this._game.destroy = function() {
         this._fire(true, "removeMultipleFingerBoard");
      }
   }



}
