// Javascript Document
'use strict';

class View_NoteBoard {

  constructor() {}

  removeFirstToneFromNoteBoard() {
    $(".noteBoard .note:eq(0)").animate({
      width: 0
    }, 100, "swing", function () {
      $(".noteBoard .note:eq(0)").remove();
      $(".noteBoard .note:eq(0)").addClass("currentFrame");
    });
  }

  addToneToNoteBoard(note) {
    $(".noteBoard .notes").append(this._getNoteHtml(note)).fadeIn(100);
  }

  _getNoteHtml(note) {
      let html = "<div class='note'>";

      if(note.showFingerNum === true && note.hasFinger()) {
        html += (note.finger+1);
      } else {
        html+="&nbsp;<br>";
      }

      switch (note.outputMode) {
          case OUTPUT_MODE.pic:
              html += "<img src=\"assets/Notes/" + note.name + ".jpg\">"
              break;
          case OUTPUT_MODE.letter:
              html += "<div class='setNoteLetterWidth'><label class='noteLetter'>" + note.name + "</label></div>";
              break;
          default: // picAndLetter
              html += "<img src=\"assets/Notes/" + note.name + ".jpg\">" + note.name;
      }
      html += "</div>";
      return html;
  }

  printNoteBoard(notes) {
      if (this.debug)
          console.log("View::printNoteBoard");


      let jNoteBoard = $(".noteBoard .notes");
      let html = "";


      for (let i = 0, max = notes.length; i < max; i++) {
          html += this._getNoteHtml(notes[i]);
      }
      jNoteBoard.html(html);
  }

  printHighlightNoteBoard(arg) {
    console.log("HIGHLIGHT Multiple");
    $(".noteBoard .notes .note").eq(arg).addClass("highlight");
  }


  observe(arg, action) {
    if (this.debug)
    console.log("View::observe", arg, action);

    if(typeof this[action] == "function") {
      this[action](arg);
    }

    switch (action) {
      case 'printRemoveFirstToneFromNoteBoard':
        this.removeFirstToneFromNoteBoard();
        break;
      case 'printAddToneToNoteBoard':
        this.addToneToNoteBoard(arg);
        break;
      case 'printNoteBoard':
          this.printNoteBoard(arg);
          break;
    }
  }
}
