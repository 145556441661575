// JS Code
'use strict';


/*
TO DO ------------------------------------- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
- mittels Listener auf GameBoard size change listen und dementsprechend anpassungen machen
*/


class GameBoard extends Publisher {

  constructor() {
    super();
    this.debug = true;
    this._gameBoard = null;
    this._gameField = null;
    this._scale = 1;
    this.zoomInFactor = 0.2;
    this.zoomOutFactor = -0.2;
    this._setMiddle = true;
    this._domReady = false;
    /*this._angularReady = false;
    this._loadReady = false;
*/


    $(document).ready(function(context = this) {
      return function() {
         console.log("GAMEBOARD:: DOM READY");
         context._domReady = true;
         if(context._domReady && context._angularReady) {
            console.log("GAMEBOARD::domReady");
           context._fire(true, "getGameFieldAndBordDimensions");
         }

      };
   }(this));
  }

/*
  init() {
    if (this.debug)
      console.log("GAMEBOARD::init");
    //this._fire(true, "getGameFieldAndBordDimensions")


}*/

  _getVisiblePosForGameField(gameBoard, gameField) {
    var w = 0;

    if (gameField.left >= 0 && gameField.right >= 0)
      w = gameField.width;
    else if (gameField.left >= 0 && gameField.right < 0)
      w = gameBoard.width - gameField.left;
    else if (gameField.left < 0 && gameField.right >= 0)
      w = gameField.width + gameField.left;
    else
      w = gameBoard.width;


    var h = 0;
    if (gameField.top >= 0 && gameField.bottom >= 0) {
      h = gameField.height;
    } else if (gameField.top >= 0 && gameField.bottom < 0) {
      h = gameBoard.height - gameField.top
    } else if (gameField.top < 0 && gameField.bottom >= 0) {
      h = gameField.height + gameField.top;
    } else {
      h = gameBoard.height;
    }

    var mL = 0,
      mT = 0;

    if (gameField.left >= 0)
      var mL = gameField.left + w / 2;
    else
      var mL = w / 2;

    if (gameField.top >= 0)
      var mT = gameField.top + h / 2;
    else
      var mT = h / 2;

    return {
      width: w,
      height: h,
      centerX: mL,
      centerY: mT
    }
  }

  zoom(factor) {
     console.log("GAMEBOARD::zoom", factor);
     this._scale += factor;
     this._fire(this._scale, "PRINT_GAMEBOARD_scale");
/*
    if (this._gameField == undefined || this._gameBoard == undefined || !this._domReady && !this._angularReady)
      return true;

    let gameBoard = this._gameBoard;
    let gameField = this._gameField;

    var visible = this._getVisiblePosForGameField(gameBoard, gameField);

    var xL = (visible.centerX - gameField.left) * factor;

    var xT = (visible.centerY - gameField.top) * factor


    var newPos = {
      left: visible.centerX - xL,
      top: visible.centerY - xT,
      height: gameField.height * factor,
      width: gameField.width * factor
    };

    console.log("GAMEBOARD::FIRE printGameFieldPos", newPos);
    this._fire(newPos, "printGameFieldPos");
    this._fire(true, "getGameFieldAndBordDimensions");*/
  }

  setMiddle() {
    if (this._gameField == undefined || this._gameBoard == undefined || !this._domReady)
      return true;

    let gameField = this._gameField;
    let gameBoard = this._gameBoard;

    let x = gameBoard.width / 2;
    let y = gameBoard.height / 2;

    let xField = gameField.width / 2;
    let yField = gameField.height / 2;

    if (this.debug)
      console.log(x, y, xField, yField);
    if (this.debug)
      console.log(gameField.left, (xField - x));

    let newPos = {
      left: gameField.left - (xField - x + gameField.left),
      top: gameField.top - (yField - y + gameField.top)
    };
    console.log("GAMEBOARD::FIRE printGameFieldPos", newPos);
    this._fire(newPos, "PRINT_GAMEBOARD_setMiddle");
    this._fire(newPos, "printGameFieldPos");
    this._fire(true, "getGameFieldAndBordDimensions");
  }

  observe(arg, action) {
    if (this.debug)
      console.log("GAMEBOARD::observe", arg, action);

    switch (action) {
      case "zoomInGameField":
        this.zoom(this.zoomInFactor);
        break;
      case "zoomOutGameField":
        this.zoom(this.zoomOutFactor);
        break;
      case "setGameFieldMiddle":
        this.setMiddle();
        break;
      case 'newGameFieldAndBordDimensions':
      console.log("GAMEBOARD:: newGameFieldBordDimensions", arg);
        this._gameBoard = arg.gameBoard;
        this._gameField = arg.gameField;
        if (this._setMiddle == true && this._domReady) {
          this._setMiddle = false;
          this.setMiddle();
          this.zoom(this.zoomInFactor)
        }
        break;

    }

  }

}
