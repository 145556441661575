// JS Code
'use strict';


class InputListener extends Publisher {

	constructor() {
		super();
		this.debug = false;

		if (this.debug)
			console.log("InputListener::construct");
		// register Listeners

		$(document).ready(function(context = this) {
			return function() {
				//$(".zoomIn").click(context.zoomIn());
				//$(".zoomOut").click(context.zoomOut());
				$("body").on("click", ".zoomIn", context.zoomIn())
				$("body").on("click", ".zoomOut", context.zoomOut())



				$(".fingerMatrix label").droppable({
					drop: context.fingerboardDropFinger()
				});
				//$(".gameBoard").on("click", ".fingerBoardButton", context.clickfingerBoardButton());
				//$(".fingerBoardButton").click(context.clickfingerBoardButton());

				$(".noteBoard").on("click", ".note", context.clickImgNoteBoard());

				$("body").on("click", ".startGame", context.clickStartGame());
				$(".stopGame").click(context.clickStopGame());
				//$(".showTipp").click(context.makeFireFunction(true, "clickShowTipp"));
				$("body").on("click", ".showTipp", context.makeFireFunction(true, "clickShowTipp"))


				$(".currentLageUp").click(context.makeFireFunction(true, "clickCurrentLageUp"));
				$(".currentLageDown").click(context.makeFireFunction(true, "clickCurrentLageDown"));



				$(".fingerMatrix").on("classChange", context.classChangeFingerMatrix());

				$("body").on("click", ".fingers div", context.clickFinger());
				//$(".fingers div").click(context.clickFinger());

				$(".settings .rotation").change(context.makeFireFunctionValue("newRotation"));
				$(".settings .showFinger").change(context.makeFireFunctionCheckbox("newShowFinger"));
				$(".settings .showHelpLines").change(context.makeFireFunctionCheckbox("newShowHelpLines"));
				$(".settings .gameType").change(context.changeGameType());
				$(".settings .lage").change(context.changeLage());
				$(".settings .group").change(context.changeGroup());
				$(".settings .tonality").change(context.changeTonality());
				$(".settings .basicTone").change(context.changeBasicTone());
				$(".settings .outputMode").change(context.changeOutputMode());
				$(".settings .fingerMode").change(context.changeFingerMode());
				$(".settings .addCondition").click(context.clickNewCondition());
				$(".settings .endConditions").on("click", "button", context.clickDeleteCondition());
				$(".settings .autoPlayingLage").change(context.changeAutoPlayingLage());
				$(".settings .noteBoardFingerNumber").change(context.changeNoteBoardFingerNumber());
				$(".settings .numNotesNoteBoard").change(context.makeFireFunctionValue("newNumNotesNoteBoard"));
				$(".settings .numNotesFingerBoard").change(context.makeFireFunctionValue("newNumNotesFingerBoard"));
				$(".settings .notesAreTurnable").change(context.makeFireFunctionCheckbox("newNotesAreTurnable"));
				$(".settings .defaultShowNames").change(context.makeFireFunctionCheckbox("newDefaultShowNames"));
				$(".settings .showTipps").change(context.makeFireFunctionCheckbox("newShowTipps"));
				$(".settings .showTippAfterNumWrongOnes").change(context.makeFireFunctionValue("newShowTippAfterNumWrongOnes"));
				$(".settings .cssCdur").change(context.makeFireFunctionCheckbox("newShowCssDur"));
				$(".settings .colorMarkPoints").change(context.makeFireFunctionSelect("newColorMarkupPoints"));
			}
		}(this));
		/*
		        $(".zoomIn").click(this.zoomIn());

		        $(".zoomOut").click(this.zoomOut());

		        $(".fingerMatrix label").droppable({
		            drop: this.fingerboardDropFinger()
		        });

		        $(".fingerBoardButton").click(this.clickfingerBoardButton());

		        $(".noteBoard").on("click", "img", this.clickImgNoteBoard());

		        $(".startGame").click(this.clickStartGame());
		        $(".stopGame").click(this.clickStopGame());
		        $(".showTipp").click(this.makeFireFunction(true, "clickShowTipp"));

		        $(".currentLageUp").click(this.makeFireFunction(true, "clickCurrentLageUp"));
		        $(".currentLageDown").click(this.makeFireFunction(true, "clickCurrentLageDown"));

		        $(".fingerMatrix").on("classChange", this.classChangeFingerMatrix());

		        $(".fingers div").click(this.clickFinger());

		        $(".settings .rotation").change(this.makeFireFunctionValue("newRotation"));
		        $(".settings .showFinger").change(this.makeFireFunctionCheckbox("newShowFinger"));
		        $(".settings .showHelpLines").change(this.makeFireFunctionCheckbox("newShowHelpLines"));
		        $(".settings .gameType").change(this.changeGameType());
		        $(".settings .lage").change(this.changeLage());
		        $(".settings .group").change(this.changeGroup());
		        $(".settings .tonality").change(this.changeTonality());
		        $(".settings .basicTone").change(this.changeBasicTone());
		        $(".settings .outputMode").change(this.changeOutputMode());
		        $(".settings .fingerMode").change(this.changeFingerMode());
		        $(".settings .addCondition").click(this.clickNewCondition());
		        $(".settings .endConditions").on("click", "button",this.clickDeleteCondition());
		        $(".settings .autoPlayingLage").change(this.changeAutoPlayingLage());
		        $(".settings .noteBoardFingerNumber").change(this.changeNoteBoardFingerNumber());
		        $(".settings .numNotesNoteBoard").change(this.makeFireFunctionValue("newNumNotesNoteBoard"));
		        $(".settings .numNotesFingerBoard").change(this.makeFireFunctionValue("newNumNotesFingerBoard"));
		        $(".settings .notesAreTurnable").change(this.makeFireFunctionCheckbox("newNotesAreTurnable"));
		        $(".settings .defaultShowNames").change(this.makeFireFunctionCheckbox("newDefaultShowNames"));
		        $(".settings .showTipps").change(this.makeFireFunctionCheckbox("newShowTipps"));
		        $(".settings .showTippAfterNumWrongOnes").change(this.makeFireFunctionValue("newShowTippAfterNumWrongOnes"));
		        $(".settings .cssCdur").change(this.makeFireFunctionCheckbox("newShowCssDur"));
		        $(".settings .colorMarkPoints").change(this.makeFireFunctionSelect("newColorMarkupPoints"));
		        */
	}

	classChangeFingerMatrix(context = this) {
		return function() {
			let sel = TYP_SHOW_MATRIX.point;
			if ($(".fingerMatrix .showName").length > 0) {
				sel = TYP_SHOW_MATRIX.name;
			}
			context._fire(sel, "newTypShowMatrix");
		}
	}

	makeFireFunctionSelect(action, context = this) {
		return function() {
			let sel = $("option:selected", this).eq(0).data("val");
			context._fire(sel, action);
		}
	}

	makeFireFunctionCheckbox(action, context = this) {
		return function() {
			let sel = $(this).is(":checked");
			context._fire(sel, action);
		}
	}


	makeFireFunctionValue(action, context = this) {
		return function() {
			let sel = $(this).val();
			context._fire(sel, action);
		}
	}

	changeRotation(context = this) {
		return function() {
			let sel = $(".rotation").val();
			context._fire(sel, "newRotation");
		}
	}

	clickImgNoteBoard(context = this) {
		return function() {
			let sel = $(this).prevAll().length;
			context._fire(sel, "clickImgNoteBoard");
		}
	}

	clickFinger(context = this) {
		return function() {
			let sel = $(this).attr("class").split(" ").filter(elem => elem.split("_")[0] == "f")[0].split("_")[1];
			//.split(" ")[0].split("finger")[1];
			context._fire(sel, "clickFingerNumber");
		}
	}

	changeAutoPlayingLage(context = this) {
		return function() {
			let sel = $(this).is(":checked");
			context._fire(sel, "newSetPlayingLageAuto");
		}
	}

	changeNoteBoardFingerNumber(context = this) {
		return function() {
			let sel = $(".noteBoardFingerNumber option:selected").eq(0).data("val");
			context._fire(sel, "showNoteBoardFingerNumber");
		}
	}

	makeFireFunction(arg, action, context = this) {
		return function() {
			context._fire(arg, action);
		}
	}


	clickDeleteCondition(context = this) {
		return function() {
			$(this).parent().remove();
			context._fire(true, "getEndCondition");
		}
	}

	clickNewCondition(context = this) {
		return function() {
			let condWin = $(".conditionWin").val();
			let condType = $(".conditionType").val();
			let condNumber = $(".conditionNumber").val();
			let condTypeJson = $(".conditionType option:selected").eq(0).data("name");

			let str = '"win": ';
			if (condWin == "Gewinnt")
				str += "true";
			else
				str += "false";

			str += ', "' + condTypeJson + '": ' + condNumber

			let html = "<div data-condition='" + str + "'>" + condWin + ": " + condNumber + " " + condType + "<button>X</button></div>";
			$(".endConditions").append(html);

			context._fire(true, "getEndCondition");
		}
	}

	angularFingerDrop(obj) {

		this._fire(obj, "dropFinger");
	}

	fingerboardDropFinger(context = this) {
		return function(ev, ui) {
			console.log("INPUTLISTENER::fingerBoardDropFinger");
			var tdIndex = $(this).index();
			var trIndex = $(this).parent().index();
			var finger = $(ui.draggable).attr("class").split(" ")[0].split("finger")[1] - 0;
			var obj = {
				finger: finger,
				row: trIndex,
				column: tdIndex
			};
			obj.showName = $(this).attr("class").split(" ").includes("showName");
			obj.showTipp = $(this).attr("class").split(" ").includes("highlight");

			console.log(obj);
			context._fire(obj, "dropFinger");
		}
	}
	/*
	    _getGameFieldAndBordDimensions() {
	        let gameField = {
	            width: $(".gameField").width(),
	            height: $(".gameField").height(),
	            left: $(".gameField").cssNumber("left"),
	            top: $(".gameField").cssNumber("top")
	        }


	        let gameBoard = {
	            width: $(".gameBord").width(),
	            height: $(".gameBord").height()
	        }

	        gameField.bottom = gameBoard.height - gameField.height - gameField.top;
	        gameField.right = gameBoard.width - gameField.width - gameField.left;

	        console.log({
	            gameField: gameField,
	            gameBoard: gameBoard
	        });

	        return {
	            gameField: gameField,
	            gameBoard: gameBoard
	        };
	    }
	    */

	zoomIn(context = this) {
		return function() {

			context._fire(true, "zoomInGameField");
		}
	}

	zoomOut(context = this) {
		return function() {
			context._fire(true, "zoomOutGameField");
		}
	}

	angularFingerBoardButtonClick(row, column) {
      console.log("CLICK");
      let jObj = $("#finger"+row+"_"+column);

      let multipleChoise = (jObj.attr("class").indexOf("highlight") != -1)

      let obj = {
         row: row,
         column: column
      };
      if (multipleChoise)
         obj.multipleChoise = true;

      obj.showName = (jObj.attr("class").indexOf("showName") != -1);
      obj.showTipp = (jObj.attr("class").indexOf("highlight") != -1);
      //console.log("INPUTLISTENER::clickFingerBoardButton", obj);
      this._fire(obj, "clickFingerBoardButton", "FingerBoard");
	}

	clickfingerBoardButton(context = this) {
		return function() {
			console.log("CLICK");
			var column = $(this).index();
			var row = $(this).parent().index();

			let multipleChoise = ($(this).attr("class").indexOf("highlight") != -1)

			let obj = {
				row: row,
				column: column
			};
			if (multipleChoise)
				obj.multipleChoise = true;

			obj.showName = ($(this).attr("class").indexOf("showName") != -1);
			obj.showTipp = ($(this).attr("class").indexOf("highlight") != -1);
			//console.log("INPUTLISTENER::clickFingerBoardButton", obj);
			context._fire(obj, "clickFingerBoardButton", "FingerBoard");
		}
	}

	clickStartGame(context = this) {
		return function() {
			context._fire(true, "clickStartGame");
		}
	}

	clickStopGame(context = this) {
		return function() {
			context._fire(true, "clickStopGame");
		}
	}

	changeGameType(context = this) {
		return function() {
			let sel = $(".settings .gameType option:selected").eq(0).data("val");
			context._fire(sel, "newGameMode");
		}
	}

	changeFingerMode(context = this) {
		return function() {

			var selected = $(".settings .fingerMode").val();

			var mode = -1;
			switch (selected) {
				case "Klicken":
					mode = FINGER_MODE.click;
					break;
				case "Finger ziehen":
					mode = FINGER_MODE.drop;
					break;
				case "Finger tippen":
					mode = FINGER_MODE.clickOnFinger;
					break;
				case "Finger greifen (mobil)":
					mode = FINGER_MODE.fingerHoldAndClick;
					break;
			}
			context._fire(mode, "newFingerMode");
		}
	}


	changeOutputMode(context = this) {
		return function() {
			var sel = null;
			switch ($(this).val()) {
				case "Gemischt":
					sel = 3;
					break;
				case "Schreibschrift":
					sel = 2;
					break;
				case "Notenschrift":
					sel = 1;
					break;
				default: // Show Noten + Schreibschrift
					sel = 0;
					break;
			}
			context._fire(sel, "newOutputMode");
		}
	}

	changeLage(context = this) {
		return function() {
			var selected = $(".settings .lage").val();
			var sel = [];
			for (var i = 0; i < selected.length; i++) {
				switch (selected[i]) {
					case "Lage 1":
						sel[sel.length] = 0;
						break;
					case "Lage 2":
						sel[sel.length] = 1;
						break;
					case "Lage 3":
						sel[sel.length] = 2;
						break;
					case "Lage 4":
						sel[sel.length] = 3;
						break;
					case "Lage 5":
						sel[sel.length] = 4;
						break;
					case "Lage 6":
						sel[sel.length] = 5;
						break;
					case "Alle Lagen":
						sel = [0, 1, 2, 3, 4, 5];
						break;
				}
			}
			context._fire(sel, "newLage");
		}
	}
	changeGroup(context = this) {
		return function() {
			var sel = null;
			switch ($(this).val()) {
				case "Gruppe 1":
					sel = 0;
					break;
				case "Gruppe 2":
					sel = 1;
					break;
				case "Gruppe 3":
					sel = 2;
					break;
				default:
					sel = -1;
					break;

			}
			context._fire(sel, "newGroup");
		}
	}

	changeTonality(context = this) {
		return function() {
			let sel = $(this).val();
			if (sel == "Alle Töne")
				sel = '';

			switch ($(this).val()) {

				case "C-Dur":
					sel = 'cDur';
					break;
				case "B-Dur":
					sel = 'bDur';
					break;
				case "D-Dur":
					sel = 'dDur';
					break;
				case "F-Dur":
					sel = 'fDur';
					break;
				case "G-Dur":
					sel = 'gDur';
					break;
				case "Nur Halbtöne":
					sel = 'invCdur';
					break;
				default:
					sel = 'whole';
					break;
			}


			context._fire(sel, "newTonality");
		}
	}
	changeBasicTone(context = this) {
		return function() {
			let sel = $(this).is(":checked");


			context._fire(sel, "newBasicTone");
		}
	}
}
