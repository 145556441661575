// Javascript Document
'use strict';

/*

Subscribe to
- GameRightOnes

Publishes to
- View

*/

class GameStatistic extends Publisher {
  constructor() {
    super();
    this._startTime = null;
    this._playTime = 0;

    this.debug = false;
    this._fingerMode = 0;
    this._stat = 0;
    this._levelCondition = [];
    /*this._bronzeCondition = [];
    this._silverCondition = [];
    this._goldCondition = [];*/
  }
  start() {
    this._startTime = (new Date()).getTime();
    this._playTime = 0;
  }

  getCurrPlayTime() {
     if(this._startTime == null)
      return Math.floor(this._playTime/1000);

     return  Math.floor(((new Date()).getTime() - this._startTime+this._playTime)/1000);
 }

  pause(obj) {
     if(obj.pause == true) {
        console.log("playTimer:: pause", obj.pause);
        this._playTime += new Date() - this._startTime;
        this._startTime = null;
     } else {
        console.log("playTimer:: pause SET", obj.pause);
        this._startTime = (new Date()).getTime();
    }
 }

  stop(obj) {

     this._playTime += new Date() - this._startTime;
     this._startTime = (new Date()).getTime();
  }

  checkMedaille(MedailleTyp) {
    const time = MedailleTyp.filter(obj => obj.fingerMode == this._fingerMode)[0].time;
    if (this._stat.playTime < time)
      return true;
    return false;
  }

  getStat(onlyUsedInCondition = true) {
    if (!onlyUsedInCondition)
      return this._stat;

    let stat = {
      win: this._stat.win
    };

    for (let i = 0; i < this._levelCondition.length; i++) {
      let cond = this._levelCondition[i];

      if (cond.type == END_CONDITON_TYPE.rightOnes) {
        stat.rightOnes = this._stat.rightOnes;
      } else if (cond.type == END_CONDITON_TYPE.wrongOnes) {
        stat.wrongOnes = this._stat.wrongOnes;
      } else if (cond.type == END_CONDITON_TYPE.rightOnesInARow) {
        stat.rightOnesInARow = this._stat.rightOnesInARow;
      } else if (cond.type == END_CONDITON_TYPE.duration) {
        stat.duration = this._stat.duration;
      } else if (cond.type == END_CONDITON_TYPE.timeUntilNextMove) {
        stat.timeUntilNextMove = this._stat.timeUntilNextMove;
      }
    }
    stat.playTime = new Date() - this._startTime;
    console.log(stat);
    return stat;
  }

  set stat(arg) {
    this._stat = arg;
    this._stat.playTime = this.getCurrPlayTime();
    this._stat.bronze = this.checkMedaille(BRONZE);
    this._stat.silver = this.checkMedaille(SILVER);
    this._stat.gold = this.checkMedaille(GOLD);
  }


  observe(arg, action) {
    if (this.debug)
      console.log("GameStatistic::observe", arg, action);

      const path = action.split("_");

      if(path[0] == "GAME") {
         switch(path[1]) {
            case "start":
               this.start();
               break;
            case "stop":
               this.stop(arg);
               break;
            case "pause":
               this.pause(arg);
               break;
         }
      }

    switch (action) {
      case "fireGameEnd":
      case "newStat":
        this.stat = arg;
        this._fire(this._stat, "STATISTIC_NEW");
        this._fire(this.getStat(), "printNewStat");
        break;
      case "SETTINGS_levelCondition":
        this._levelCondition = arg;
        break;
        /*case "SETTINGS_bronzeCondition":
          this._bronzeCondition = arg;
          break;
        case "SETTINGS_silverCondition":
          this._silverCondition = arg;
          break;
        case "SETTINGS_goldCondition":
          this._goldCondition = arg;
          break;*/
      case "SETTINGS_fingerMode":
        this._fingerMode = arg;
        break;

    }
  }
}
